import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Column } from "primereact/column";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { dateCliInvConvert } from "../../../helpers/dateHelper";
import { renderClientsInvoicesCTA } from "../../../helpers/invoiceHelpers";
import currencySymbol from "../../../constants/currencies.js";
import payStatusConsts from "../../../constants/invPaymetConsts";
import { useFetchRecentClientInvoices } from "./ClientDashboardCurrencyApi";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import PaymentStatusWise from "../../Invoices/elements/paymentStatusWise.jsx";
import { motion } from 'framer-motion';
import ClientPaymentStatus from "./elements/clientPaymentStatusWise.jsx";
import cliInvStatusConsts from "../../../constants/cliInvStatusConsts.js";

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);
  const clientName =
    (rowData.cli_inv_for.client_name || rowData.cli_inv_for.client_legalentity_name);
  const displayName = showFullname
    ? clientName
    : clientName.length <= 15
      ? clientName
      : clientName.slice(0, 15) + "...";

  return (
    <div
      className={`member-name-client member-name${showFullname ? " active" : ""}`}
      onMouseEnter={() => {
        if (clientName.length > 15) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      <span>{displayName}</span>
    </div>
  );
};

const RecentClientInvoices = (props) => {
  const currencySum = props.sum;
  const dispatch = useDispatch();
  const dashboradDate = useSelector(
    (state) => state.invoice.dashboardDateRange
  );
  const dashboradCurrency = useSelector(
    (state) => state.invoice.dashboardCurrency
  )
  const user = useSelector(
    (state) => state.auth.user
  )
  const token = useSelector(
    (state) => state.auth.token
  )

  const { appSettings } = useSelector((state) => state.appSettings);
  const { data: products = [], isLoading, refetch: refetchRecentInvoice } = useFetchRecentClientInvoices(
    token,
    new Date(moment(dashboradDate[0]).tz(global.config.getTimeZone()).startOf('day')).toISOString(),
    new Date(moment(dashboradDate[1]).tz(global.config.getTimeZone()).endOf('day')).toISOString(),
    100,
    dashboradCurrency,
    currencySum
  );

  useEffect(() => {
    dispatch(setHeaderText("Dashboard"));
    refetchRecentInvoice();
  }, [dashboradDate, dashboradCurrency, currencySum, dispatch, token]);

  const renderPaymentStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.cli_inv_payment_status === payStatusConsts.Payment_Processing) {
      statusClass = "payment-processeing";
      statusName = "Payment Processing";
    } else if (rowData.cli_inv_payment_status === payStatusConsts.Pending) {
      const startDate = moment().tz(global.config.getTimeZone());
      const endDate = moment(rowData.cli_inv_due_date).tz(global.config.getTimeZone());
      const diffInDays = endDate.diff(startDate, 'days');
      if (diffInDays < 0) {
        statusClass = "overdue";
        statusName = "Overdue";
      } else {
        statusClass = "pending";
        statusName = "Pending";
      }
    } else if (rowData.cli_inv_payment_status === payStatusConsts.Manually_Processed) {
      statusClass = "manual";
      statusName = "Manually processed";
    } else if (rowData.cli_inv_payment_status === payStatusConsts.Sucessfully_Processed) {
      statusClass = "sucessfully-processed";
      statusName = "Payment Processed";
    } else if (rowData.cli_inv_payment_status === payStatusConsts.Cancelled) {
      statusClass = "overdue";
      statusName = "Cancelled";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{global.config.locate(appSettings && appSettings.language, statusName)}</span>
    );
  };

  const ConvertCurrency = (code) => {
    const filteredCurrencies = Object.entries(currencySymbol).filter(([key, value]) => key === code);
    if (filteredCurrencies.length === 1) {
      var currency = filteredCurrencies[0][1];
      return currency;
    } else {
      return code;
    }
  }
  const renderAmount = (rowData) => {
    var totalAmount = rowData.cli_inv_total_amount.toLocaleString('en-US', { style: 'currency', currency: rowData.cli_inv_currency });
    return <span> {totalAmount}</span>;
  };

  const renderStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.cli_inv_status === cliInvStatusConsts.Invoice_Send) {
      statusClass = "pending";
      statusName = "Invoice Sent";
    } else if (rowData.cli_inv_status === cliInvStatusConsts.Pending) {
      // statusClass = "pending";
      // statusName = "Pending";
      const startDate = moment().tz(global.config.getTimeZone());
      const endDate = moment(rowData.cli_inv_due_date).tz(global.config.getTimeZone());
      const diffInDays = endDate.diff(startDate, "days");
      if (diffInDays < 0) {
        statusClass = "overdue";
        statusName = "Overdue";
      } else {
        statusClass = "pending";
        statusName = "Pending";
      }
    } else if (rowData.cli_inv_status === cliInvStatusConsts.Rejected) {
      statusClass = "overdue";
      statusName = "Rejected";
    } else if (rowData.cli_inv_status === cliInvStatusConsts.Canceled) {
      statusClass = "overdue";
      statusName = "Canceled";
    } else if (rowData.cli_inv_status === cliInvStatusConsts.Payment_Processed) {
      statusClass = "manual";
      statusName = "Payment Processed";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };
  return (
    <div className="invoice-outer">
      <div className="recent-title">{global.config.locate(appSettings && appSettings.language, 'Recent Invoices')}</div>
      <div className="card">
        {isLoading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#405BFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (

          <motion.div
            ref={componentRef}
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.3 }}
          >

            <DataTable
              value={products.slice(0, 5)}
              removableSort
              tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
              paginator={false}
              paginatorClassName="recent-invoices-pagination"
              rows={5} // Set the number of rows per page
              emptyMessage="No invoices found"
            >
              <Column
                field="cli_inv_number"
                header={global.config.locate(appSettings && appSettings.language, 'Invoice ID')}
                sortable
                style={{ minWidth: "200px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="cli_inv_due_date"
                body={dateCliInvConvert}
                header={global.config.locate(appSettings && appSettings.language, 'Due Date')}
                sortable
                style={{ minWidth: "230px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="cli_inv_for.client_name"
                header={global.config.locate(appSettings && appSettings.language, 'User Name')}
                body={useRenderMemberName}
                sortable
                sortField="clientName" // Use a different sortField to avoid confusion with the original field
                sortFunction={(e) => {
                  const dataCopy = [...products];
                  dataCopy.sort((rowData1, rowData2) => {
                    const clientName1 =
                      (rowData1.cli_inv_for.client_name ||
                        rowData1.cli_inv_for.client_legalentity_name);

                    const clientName2 =
                      (rowData2.cli_inv_for.client_name ||
                        rowData2.cli_inv_for.client_legalentity_name);

                    // Implement your custom comparison logic here
                    return e.order === 1
                      ? clientName1.localeCompare(clientName2)
                      : clientName2.localeCompare(clientName1);
                  });
                  return dataCopy;
                }}
                style={{ minWidth: "240px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="cli_inv_status"
                header="Status"
                className="fixed-sec41"
                body={renderStatus}
                sortable
                style={{ paddingRight: "44px" }}
              ></Column>
              <Column
                field="cli_inv_total_amount"
                header={global.config.locate(appSettings && appSettings.language, 'Amount')}
                body={renderAmount}
                sortable
                style={{ padding: "20px" }}
              ></Column>
              <Column
                field="_id"
                body={renderClientsInvoicesCTA}
                style={{}}
              ></Column>
            </DataTable>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default RecentClientInvoices;