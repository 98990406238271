import React from 'react';
import { useDispatch } from "react-redux";
import { AES } from 'crypto-js';
import { Link } from 'react-router-dom';
import { setUserRoleName } from "../features/users/usersSlice.js";
import userTypeName from "../constants/userType";

export function renderCTA(rowData) {
  return (
    <Link to={`../invoice/view/${rowData._id}`} className="cta-link">
      Details
    </Link>
  );
}

export function renderClientInvoiceCTA(rowData) {
  const encryptedId = AES.encrypt(rowData._id, 'encryptionKey').toString();
  const encryptedCliId = AES.encrypt(rowData.cli_inv_for._id, 'encryptionKey').toString();
  const invId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');

  return (
    <Link to={`../view/${invId}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

export function renderVendorInvoiceCTA(rowData) {
  const encryptedId = AES.encrypt(rowData._id, 'encryptionKey').toString();
  const encryptedVenId = AES.encrypt(rowData.ven_inv_for._id, 'encryptionKey').toString();
  const venInvId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  const encodedId = encodeURIComponent(encryptedVenId).replace(/\//g, '%2F');

  return (
    <Link to={`../view/${venInvId}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

export function renderClientsInvoicesCTA(rowData) {
  const encryptedId = AES.encrypt(rowData._id, 'encryptionKey').toString();
  const encryptedCliId = AES.encrypt(rowData.cli_inv_for._id, 'encryptionKey').toString();
  const caliInvId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');

  return (
    <Link to={`../invoices/view/${caliInvId}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

export function renderVendorsInvoicesCTA(rowData) {
  const encryptedId = AES.encrypt(rowData._id, 'encryptionKey').toString();
  const encryptedVenId = AES.encrypt(rowData.ven_inv_for._id, 'encryptionKey').toString();
  const venInvId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  const encodedId = encodeURIComponent(encryptedVenId).replace(/\//g, '%2F');

  return (
    <Link to={`../invoices/view/${venInvId}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

export function renderClientProjectCTA(rowData) {
  const encryptedCliId = AES.encrypt(rowData.client_name._id, 'encryptionKey').toString();
  const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');

  return (
    <Link to={`/client/projects/details/${rowData._id}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

export function RenderUserCTA(rowData) {
  const dispatch = useDispatch();
  const encryptedId = AES.encrypt(rowData._id, 'encryptionKey').toString();
  const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  const handleLinkClick = async () => {
    localStorage.setItem('activeTab', '0');
    if (rowData.role_id._id === userTypeName.Team_Manager) {
      await dispatch(setUserRoleName(userTypeName.Team_Manager));
    } else {
      await dispatch(setUserRoleName("otheruser"));
    }
  };

  return (
    <Link to={`/user/profile/${encodedId}`} className="cta-link" onClick={handleLinkClick}>
      Details
    </Link>
  );
}

export function renderCTA2(rowData) {
  return (
    <a href={rowData._id} className="cta-link">
      Details2
    </a>
  );
}