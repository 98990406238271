import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import { DatePicker } from "rsuite";
import Pusher from "pusher-js";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import Spinner from "../../../Animations/Spinner.jsx";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import WeekTotalDisplay from "./helpers/WeekTotalDisplay.jsx";
import ThisWeekTotalDisplay from "./helpers/ThisWeekTotalDisplay.jsx";
import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useGroupedTimerDataImportData, useTimerDataImportData, } from "./TimerDataImportDataApi.jsx";
import { useTimeZone, } from "../../Timer/tracker/TimerDataApi.jsx";
import { checkTimer } from "./services.js";
import { setTimerData } from "../../../../features/timerData/timerDataSlice.js";
import TimeCardImportData from "./trackerImportDataModules/CardImportData.jsx";
import cancelImage from "../../../../images/singleinvoice/cancel.svg";
import tick from "../../../../images/icons/tick-arrow.svg";
import {
  setClientData,
  setClientIdData,
  setIsRunningData,
  setIsbillableData,
  setProjectData,
  setProjectIdData,
  setRunningTaskData,
  setSprintIdData,
  setSprintNameData,
  setTagData,
  setTagIdData,
  setTaskData,
  setTimeData,
} from "../../../../features/timeTrackerData/timeTrackerLogSlice.js";
import {
  formatChange,
  convertTo24HourFormat,
  convertTo12HourFormat,
  isEndTimeGreater,
  validateInput,
  convertTo24Hour,
} from "./services/timeFormat.js";
import TimeZoneModal from "../../../Modal/SetDefaultModal.jsx";
import { useFetchUsersTimer } from "../../../../components/TimeTracker/TimeTrackerTeam/TimeTrackerTeamMembers/teamMemberApi.jsx";

const TrackerImportData = (props) => {
  const [taskModalIsOpen, setTaskModalIsOpen] = useState(false);
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskNameEdit, setTaskNameEdit] = useState("");
  const [taskError, setTaskError] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [sprintName, setSprintName] = useState("");
  const [sprintId, setSprintId] = useState("");
  const [projectIdEdit, setProjectIdEdit] = useState("");
  const [projectNameEdit, setProjectNameEdit] = useState("");
  const [sprintNameEdit, setSprintNameEdit] = useState("");
  const [sprintIdEdit, setSprintIdEdit] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [hrEdit, setHrEdit] = useState(0);
  const [minEdit, setMinEdit] = useState(0);
  const [secEdit, setSecEdit] = useState(0);
  const [dateEdit, setDateEdit] = useState(new Date());
  const [tag, setTag] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [tagEdit, setTagEdit] = useState(null);
  const [tagIdEdit, setTagIdEdit] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [runningTask, setRunningTask] = useState(null);
  const [stopRunning, setStopRunning] = useState(null);
  const [timerUpdate, setTimerUpdate] = useState(null);
  const [customeTime, setCustomeTime] = useState(false);
  const [customeStartTime, setCustomeStartTime] = useState(new Date());
  const [customeEndTime, setCustomeEndTime] = useState(new Date());
  const [isRunning, setIsRunning] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [projectValidation, setProjectValidation] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [insertionLoading, setInsertionLoading] = useState(false);
  const [uManualStartTimeError, setUManualStartTimeError] = useState(false);
  const [uManualEndTimeError, setUManualEndTimeError] = useState(false);
  const [uDuration, setUDuration] = useState(null);
  const [uManualFormatedTimeError, setUManualFormatedTimeError] =
    useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [showTimeZoneModal, setShowTimeZoneModal] = useState(false);
  var zoneChangeConfirm = false;
  zoneChangeConfirm = localStorage.getItem("zoneChangeConfirm");

  const [client, setClient] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [isBillable, setIsBillable] = useState(null);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const trackerOuterRef1 = useRef(null);
  const trackerOuterRef = useRef(null);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const showingDropdownRef = useRef(null);
  const trackerOuterRef2 = useRef(null);
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const start12HrTimeInputRef = useRef(null);
  const end12HrTimeInputRef = useRef(null);
  const [letterCount, setLetterCount] = useState(0);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [checkTimerStatus, setCheckTimerStatus] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const timerData = useSelector((state) => state.timerData.timerDataState);
  const timeTrackerLog = useSelector((state) => state.timeTrackerLog);

  const [selectedUser, setSelectedUser] = useState(user._id);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    setEndTimeManual24("09:30");
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const trackerOuterElement = trackerOuterRef.current;
    if (trackerOuterElement) {
      const height = trackerOuterElement.offsetHeight;
      setIsActive(height > 21);
    }
  });

  useEffect(() => {
    const trackerOuterElement = trackerOuterRef1.current;
    if (trackerOuterElement) {
      const height = trackerOuterElement.offsetHeight;
      setIsActive(height > 21);
    }
  });

  const { data: groupedTimerData = [], isLoading: isGroupedTimerDataLoading, refetch: refetchGroupedTimerData, } = useGroupedTimerDataImportData(token);
  const { data: timer = [], isLoading: isTimerDataLoading, refetch: refetchTimerData, } = useTimerDataImportData(token);
  const { data: timeZoneData = [], isLoading: isTimeZoneDataLoading, refetch: refetchTimeZoneDatas, isError: timeZoneDataError, } = useTimeZone(user && user._id, token);

  useEffect(() => {
    const content = trackerOuterRef2?.current?.innerText;
    const count = content && content.replace(/\s/g, "").length;
    setLetterCount(count ? count : 0);
  });

  useEffect(() => {
    refetchTimerData();
    refetchGroupedTimerData();
  }, [selectedUser]);

  useEffect(() => {
    if (user && !isTimeZoneDataLoading) {
      if (timeZoneData === null) {
        setShowTimeZoneModal(true);
      }
      if (
        timeZoneData &&
        (timeZoneData.currency === null || timeZoneData.timeZone === null)
      ) {
        setShowTimeZoneModal(true);
      }
    }
    refetchTimeZoneDatas();
  }, [user, token, timerData]);

  useEffect(() => {
    global.config.activityLog(
      window.location.href,
      "Timer",
      `User entered into Timer`
    );
    var gettime =
      timeTrackerLog && timeTrackerLog.time
        ? timeTrackerLog.time
        : localStorage.getItem("time") !== "undefined"
          ? JSON.parse(localStorage.getItem("time"))
          : null;
    var Running =
      localStorage.getItem("isRunning") !== "undefined"
        ? JSON.parse(localStorage.getItem("isRunning"))
        : null;
    var getTask =
      timeTrackerLog && timeTrackerLog.task
        ? timeTrackerLog.task
        : localStorage.getItem("task") !== "undefined"
          ? JSON.parse(localStorage.getItem("task"))
          : null;
    var getTag =
      timeTrackerLog && timeTrackerLog.tag
        ? timeTrackerLog.tag
        : localStorage.getItem("tag") !== "undefined"
          ? JSON.parse(localStorage.getItem("tag"))
          : null;
    var getTagId =
      timeTrackerLog && timeTrackerLog.tagId
        ? timeTrackerLog.tagId
        : localStorage.getItem("tagId") !== "undefined"
          ? JSON.parse(localStorage.getItem("tagId"))
          : null;
    var getProject =
      timeTrackerLog && timeTrackerLog.project
        ? timeTrackerLog.project
        : localStorage.getItem("project") !== "undefined"
          ? JSON.parse(localStorage.getItem("project"))
          : null;
    var getProjectId =
      timeTrackerLog && timeTrackerLog.projectId
        ? timeTrackerLog.projectId
        : localStorage.getItem("projectId") !== "undefined"
          ? JSON.parse(localStorage.getItem("projectId"))
          : null;
    var getSprintName =
      timeTrackerLog && timeTrackerLog.sprintName
        ? timeTrackerLog.sprintName
        : localStorage.getItem("sprintName") !== "undefined"
          ? JSON.parse(localStorage.getItem("sprintName"))
          : null;
    var getSprintId =
      timeTrackerLog && timeTrackerLog.sprintId
        ? timeTrackerLog.sprintId
        : localStorage.getItem("sprintId") !== "undefined"
          ? JSON.parse(localStorage.getItem("sprintId"))
          : null;
    var isBillable =
      timeTrackerLog && timeTrackerLog.isbillable
        ? timeTrackerLog.isbillable
        : localStorage.getItem("isbillable") !== "undefined"
          ? JSON.parse(localStorage.getItem("isbillable"))
          : null;
    var client =
      timeTrackerLog && timeTrackerLog.client
        ? timeTrackerLog.client
        : localStorage.getItem("client") !== "undefined"
          ? JSON.parse(localStorage.getItem("client"))
          : null;
    var clientId =
      timeTrackerLog && timeTrackerLog.clientId
        ? timeTrackerLog.clientId
        : localStorage.getItem("clientId") !== "undefined"
          ? JSON.parse(localStorage.getItem("clientId"))
          : null;
    var getRunningTask =
      timeTrackerLog && timeTrackerLog.runningTask
        ? timeTrackerLog.runningTask
        : localStorage.getItem("runningTask") !== "undefined"
          ? JSON.parse(localStorage.getItem("runningTask"))
          : null;
    setEditedId(null);
    setTaskNameEdit("");
    setProjectIdEdit(null);
    setProjectNameEdit(null);
    setSprintIdEdit("");
    setSprintNameEdit("");
    setHrEdit(null);
    setMinEdit(0);
    setSecEdit(0);
    setDateEdit(new Date());
    setTag(null);
    setTagId(null);
    setTagIdEdit(null);
    setTagEdit(null);
    setTaskName("");
    if (gettime) {
      setStartTime(gettime);
    }
    if (Running) {
      setIsRunning(Running);
    }
    if (getTask) {
      setTaskName(getTask);
    } else {
      setTaskName(null);
    }
    if (getProject) {
      setProjectName(getProject);
    } else {
      setProjectName(null);
    }
    if (getRunningTask) {
      setRunningTask(getRunningTask);
    }
    if (getTag) {
      setTag(getTag);
    }
    if (getTagId) {
      setTagId(getTagId);
    }
    if (client) {
      setClient(client);
    }
    if (clientId) {
      setClientId(clientId);
    }
    if (getProjectId) {
      setProjectId(getProjectId);
    }
    if (getSprintName) {
      setSprintName(getSprintName);
    } else {
      setSprintName("");
    }
    if (getSprintId) {
      setSprintId(getSprintId);
    } else {
      setSprintId("");
    }
    if (isBillable) {
      setIsBillable(isBillable);
    }
    refetchGroupedTimerData();
    Modal.setAppElement("body");
    refetchTimerData();
  }, [stopRunning, timerUpdate, timerData, timeTrackerLog]);

  const handleEndClick = () => {
    var localTime = moment()
      .tz(global.config.getTimeZone())
      .format("YYYY-MM-DD HH:mm:ss");

    const runTask =
      localStorage.getItem("runningTask") !== "undefined"
        ? JSON.parse(localStorage.getItem("runningTask"))
        : null;
    setStopLoading(true);
    const postData = {
      id: runTask,
      endTime: localTime,
      user: user._id,
      task: taskName,
      projectName: projectName,
      project: projectId,
      tagName: tag,
      tag: tagId,
      client: client,
      clientId: clientId,
      billable: isBillable,
      tz: global.config.getTimeZone(),
      date: localTime,
    };

    if (sprintId) {
      postData.sprintName = sprintName;
      postData.sprint = sprintId;
    }

    axios
      .post("/api/time-track/stop-timer", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsRunning(false);
        setStopLoading(false);
        setTaskName("");
        setTag(null);
        setTagId(null);
        setProjectName(null);
        setProjectId(null);
        setRunningTask(null);
        setSprintName("");
        setSprintId("");
        localStorage.removeItem("startTime");
        dispatch(setTimeData(null));
        localStorage.removeItem("project");
        dispatch(setProjectData(null));
        localStorage.removeItem("task");
        dispatch(setTaskData(null));
        localStorage.removeItem("isRunning");
        dispatch(setIsRunningData(false));
        localStorage.removeItem("runningTask");
        dispatch(setRunningTaskData(null));
        localStorage.removeItem("tag");
        dispatch(setTagData(null));
        localStorage.removeItem("tagId");
        dispatch(setTagIdData(null));
        localStorage.removeItem("projectId");
        dispatch(setProjectIdData(null));
        localStorage.removeItem("sprintName");
        dispatch(setSprintNameData(null));
        localStorage.removeItem("sprintId");
        dispatch(setSprintIdData(null));
        localStorage.removeItem("client");
        dispatch(setClientData(null));
        localStorage.removeItem("clientId");
        dispatch(setClientIdData(null));
        localStorage.removeItem("isBillable");
        dispatch(setIsbillableData(false));
        dispatch(setTimerData(Date.now()));
        setStopRunning((prevState) => !prevState);
        refetchTimerData();
      })
      .catch((error) => {
        console.log(error);
        global.config.slackMessage(error.toString());
      });
  };

  const handleInsertAndClear = async () => {
    try {
      setInsertionLoading(true);

      const response = await axios.post("/api/time-track/importdata/insert/and/clear/timer", {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { insertedCount, deletedCount } = response.data;

      toast.success(`Successfully inserted ${insertedCount} entries.`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      refetchTimerData();
      props.onCancel();

    } catch (error) {
      console.error("Error in handleInsertAndClear:", error);
      global.config.slackMessage(error.toString());

      toast.error("Failed to insert and clear data. Please try again.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setInsertionLoading(false);
    }
  };

  var start =
    localStorage.getItem("startTime") !== "undefined"
      ? JSON.parse(localStorage.getItem("startTime"))
      : null;
  let customeTimeInput;
  if (customeTime) {
    customeTimeInput = (
      <>
        <p className="time-range">
          Start:{" "}
          <DatePicker
            className="date-pick-width"
            selected={customeStartTime}
            onChange={(date) => setCustomeStartTime(date)}
            showTimeSelect
            timeFormat="hh:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm:ss aa"
            timeCaption="Time"
          />
        </p>
        <p className="time-range">
          End:{" "}
          <DatePicker
            className="date-pick-width"
            selected={customeEndTime}
            onChange={(date) => setCustomeEndTime(date)}
            showTimeSelect
            timeFormat="hh:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm:ss aa"
            timeCaption="Time"
          />
        </p>
      </>
    );
  } else {
    customeTimeInput = <></>;
  }

  function formatTime(value) {
    return value < 10 ? `0${value}` : value;
  }

  const timeValidate = (timeString) => {
    const timePattern = /^(1[0-2]|0[1-9]):([0-5][0-9]) [APap][Mm]$/;
    const isValidFormat = timePattern.test(timeString);
    return isValidFormat;
  };

  const covertTimeSatmp = (dateEdit, sT) => {
    const date = new Date(dateEdit);
    const timeString = sT;
    const datePart = date.toLocaleDateString("en-US");
    const timePart = timeString;

    // Combine the date and time strings
    const combinedDateTimeString = `${datePart} ${timePart}`;

    const formattedTime = moment(
      combinedDateTimeString,
      "MM/DD/YYYY hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");
    const dateObject = new Date(combinedDateTimeString);
    // Set the locale to English
    const formattedDate = dateObject.toLocaleString("en-US");
    return formattedDate;
  };

  const autoLoad = (
    task,
    project_id,
    projectName,
    tag_name,
    tag_id,
    client,
    clientId,
    sprint_name,
    _id,
    billable
  ) => {
    var time = moment().tz(global.config.getTimeZone());
    time = time.format();
    setTaskName(task);
    setProjectName(projectName);
    setProjectId(project_id);
    setTag(tag_name);
    setTagId(tag_id);
    setClient(client);
    setClientId(clientId);
    setIsBillable(billable);
    setDropdownVisible(false);
  };

  const [weekdata, setData] = useState(null);
  useEffect(() => {
    const getWeeklyHour = async () => {
      await axios
        .post(
          "/api/time-track/weekly-hours",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setData(
            `${formatTime(
              Math.floor(parseInt(result.data) / 3600)
            )}:${formatTime(
              Math.floor(parseInt((result.data % 3600) / 60))
            )}:${formatTime(Math.floor(parseInt(result.data) % 60))}`
          );
        });
    };
    getWeeklyHour();
    setFilteredData(groupedTimerData);
  }, [timerUpdate, stopRunning, timerData]);

  useEffect(() => {
    setFilteredData(groupedTimerData);
  }, [groupedTimerData]);

  const handleClickOutsideDropdown = (event) => { };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const [startTimeManual, setStartTimeManual] = useState("09:00 AM");
  const [startTimeTemp, setStartTimeTemp] = useState("09:00 AM");
  const [startTimeManual24, setStartTimeManual24] = useState("09:00");
  const [endTimeManual, setEndTimeManual] = useState("09:30 AM");
  const [endTimeTemp, setEndTimeTemp] = useState("09:30 AM");
  const [endTimeManual24, setEndTimeManual24] = useState("09:30");
  const [uFormatedTimeManual, setUFormatedTimeManual] = useState("00:00:00");

  function isValidTimeFormat(timeString) {
    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] [APMapm]{2}$/;
    return timeRegex.test(timeString);
  }

  const parseTimeInput = (input) => {
    input = input.trim().toLowerCase();
    // const timeRegex = /^(\d{1,2}):?(\d{1,2})?\s*([ap]m?)?$/;
    let timeRegex = /^(\d{1,2}):?(\d{2})?\s*([ap]m?)?$/;
    let match = input.match(timeRegex);

    if (!match) {
      timeRegex = /^(\d{1,2}):?(\d{1,2})?\s*([ap]m?)?$/;
      match = input.match(timeRegex);
      if (!match) return false;
    }

    let [_, hour, minutes, period] = match;
    hour = hour.padStart(2, "0");
    minutes = minutes ? minutes.padStart(2, "0") : "00";
    period = period
      ? period.toUpperCase()
      : parseInt(hour, 10) < 12
        ? "AM"
        : "PM";
    if (parseInt(hour, 10) > 12) return false;
    return `${hour}:${minutes} ${period}`;
  };

  const timeFormatChange = (time) => {
    const input = parseTimeInput(time);
    if (input) {
      const momentTime = moment(input, "hh:mm A");
      return momentTime.format("hh:mm A");
    }
    return false;
  };

  function convertToStandardTime(timeString) {
    const isPM = timeString.toLowerCase().includes("p");
    const hours = parseInt(timeString, 10);
    if (isNaN(hours)) {
      return "Invalid format";
    }
    const standardTime = moment({ hour: hours % 12, minute: 0 }).format(
      "hh:mm A"
    );
    return isPM ? standardTime.replace("AM", "PM") : standardTime;
  }

  function isValidCustomTimeFormat(timeString) {
    const customTimeRegex = /^(0?[1-9]|1[0-2])[apAP]$/;
    return customTimeRegex.test(timeString);
  }

  const subOneHour = (startTime) => {
    // Parse the time string
    const timeString = startTime;
    const time = moment(timeString, "hh:mm A");
    // Add one hour to the time
    const newTime = time.subtract(1, "hour");
    // Format the new time
    const formattedTime = newTime.format("hh:mm A");
    return formattedTime;
  };

  const timeFomatChange2 = (time) => {
    const input = time.trim().toLowerCase();
    let converted = "";
    // Check if the input matches the supported time format
    if (/^\d{1,2}:\d{2}[ap]$/i.test(input)) {
      const [hour, minutes] = input.match(/\d+/g);
      const isPM = input.includes("p");
      let formattedHour = parseInt(hour, 10);
      if (isPM && formattedHour !== 12) {
        formattedHour += 12;
      }
      if (!isPM && formattedHour === 12) {
        formattedHour = 0;
      }
      formattedHour = formattedHour % 12 || 12; // Convert 0 to 12
      converted = `${formattedHour.toString().padStart(2, "0")}:${minutes} ${isPM ? "PM" : "AM"
        }`;
    } else {
      // Invalid time format
      converted = false;
    }
    return converted;
  };
  const manualChangeStartTime = (startTime) => {
    let formattedTime = timeFormatChange(startTime);

    if (formattedTime === false) {
      setUManualStartTimeError(true);
      return;
    }

    setStartTimeManual(formattedTime);
    setStartTimeTemp(formattedTime);
    setUManualStartTimeError(false);
    setUManualEndTimeError(false);

    const timeString1 = formattedTime.trim();
    const timeString2 = endTimeManual.trim();
    const format = "h:mm A";
    const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
    const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
    const timeDifferenceInSeconds = time2.diff(time1, "seconds");

    if (timeDifferenceInSeconds > 0) {
      setUDuration(parseInt(timeDifferenceInSeconds));
      setUFormatedTimeManual(
        `${formatTime(
          Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
        )}:${formatTime(
          Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
        )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
      );
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    } else {
      setUManualStartTimeError(true);
    }
  };
  //end of 12 hour format

  // 24 hour format
  const manualChangeStartTime24 = (startTime) => {
    setStartTimeManual24(startTime);
    if (formatChange(startTime)) {
      var convertedTim = convertTo24HourFormat(startTime);

      setStartTimeManual24(convertedTim);
      setUManualStartTimeError(false);
    } else {
      setUManualStartTimeError(true);
    }
    startTime = convertTo12HourFormat(convertedTim);
    if (timeFomatChange2(startTime) !== false) {
      startTime = timeFomatChange2(startTime);
    }
    var oldTime = null;
    if (isValidCustomTimeFormat(startTime)) {
      var oldTime = startTime;
      startTime = convertToStandardTime(startTime);
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
    } else {
      const timePart = startTime.split(" ")[0];
      var hours = parseInt(timePart.split(":")[0], 10);
      if (hours > 12 && hours < 24) {
        const twelveHourTime = moment(timePart, "HH:mm").format("hh:mm A");
        if (twelveHourTime !== "Invalid date") {
          startTime = twelveHourTime;
        }
        setUManualStartTimeError(false);
        setUManualEndTimeError(false);
      }
    }
    if (timeValidate(startTime)) {
      const timeString1 = startTime.trim();
      var timeString2 = endTimeManual && endTimeManual.trim();
      const format = "h:mm A";
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");

      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTimeManual(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUManualStartTimeError(false);
        setUManualEndTimeError(false);
        setUManualFormatedTimeError(false);
      } else {
        setUManualStartTimeError(true);
      }
    } else {
      setUManualStartTimeError(true);
    }
    setStartTimeManual(startTime);
  };

  const manualChangeEndTime = (endTime) => {
    let formattedTime = timeFormatChange(endTime);

    if (formattedTime === false) {
      setUManualEndTimeError(true);
      return;
    }

    setEndTimeManual(formattedTime);
    setEndTimeTemp(formattedTime);

    const timeString1 = startTimeManual.trim();
    const timeString2 = formattedTime.trim();
    const format = "h:mm A";
    const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
    const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
    const timeDifferenceInSeconds = time2.diff(time1, "seconds");

    if (timeDifferenceInSeconds > 0) {
      setUDuration(parseInt(timeDifferenceInSeconds));
      setUFormatedTimeManual(
        `${formatTime(
          Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
        )}:${formatTime(
          Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
        )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
      );
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    } else {
      setUManualEndTimeError(true);
    }
  };

  const manualChangeEndTime24 = (endTime) => {
    setEndTimeManual24(endTime);
    if (formatChange(endTime)) {
      var convertedTim = convertTo24HourFormat(endTime);
      setEndTimeManual24(convertedTim);
      setUManualEndTimeError(false);
    } else {
      setUManualEndTimeError(true);
    }
    endTime = convertTo12HourFormat(convertedTim);
    if (timeFomatChange2(endTime) !== false) {
      endTime = timeFomatChange2(endTime);
    }
    var oldTime = null;
    if (isValidCustomTimeFormat(endTime)) {
      oldTime = endTime;
      endTime = convertToStandardTime(endTime);
    } else {
      const timePart = endTime.split(" ")[0];
      const hours = parseInt(timePart.split(":")[0], 10);
      if (hours > 12 && hours < 24) {
        const twelveHourTime = moment(timePart, "HH:mm").format("hh:mm A");
        if (twelveHourTime !== "Invalid date") {
          endTime = twelveHourTime;
        }
      }
    }
    if (timeValidate(endTime)) {
      var timeString1 = startTimeManual.trim();
      const timeString2 = endTime.trim();
      const format = "h:mm A";
      if (isValidCustomTimeFormat(oldTime)) {
        timeString1 = subOneHour(endTime).trim();
        setStartTimeManual(timeString1);
      }
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");
      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTimeManual(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUManualStartTimeError(false);
        setUManualEndTimeError(false);
        setUManualFormatedTimeError(false);
      } else {
        setUManualEndTimeError(true);
      }
    } else {
      setUManualEndTimeError(true);
    }
    setEndTimeManual(endTime);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startInputRef.current &&
        !startInputRef.current.contains(event.target)
      ) {
        if (
          formatChange(startInputRef.current.value) &&
          validateInput(startInputRef.current.value)
        ) {
          manualChangeStartTime24(startInputRef.current.value);
        } else {
          manualChangeStartTime24(convertTo24Hour(startTimeManual));
        }
      }
      if (endInputRef.current && !endInputRef.current.contains(event.target)) {
        if (formatChange(endInputRef.current.value)) {
          manualChangeEndTime24(endInputRef.current.value);
        } else {
          manualChangeEndTime24(convertTo24Hour(endTimeManual));
        }
      }
      if (
        start12HrTimeInputRef.current &&
        !start12HrTimeInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(
          start12HrTimeInputRef.current.value
        );
        if (formattedTime === false) {
          manualChangeStartTime(startTimeTemp);
        } else {
          manualChangeStartTime(start12HrTimeInputRef.current.value);
        }
      }
      if (
        end12HrTimeInputRef.current &&
        !end12HrTimeInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(end12HrTimeInputRef.current.value);
        if (formattedTime === false) {
          manualChangeEndTime(endTimeTemp);
        } else {
          manualChangeEndTime(end12HrTimeInputRef.current.value);
        }
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [
    manualChangeStartTime24,
    manualChangeEndTime24,
    manualChangeStartTime,
    manualChangeEndTime,
  ]);

  function filterAndFindExtremes(dates) {
    const dateObject = moment();
    const formattedDate = dateObject.format("YYYY-MM-DD");
    const currentDate = new Date(formattedDate);
    const currentWeekStartDate = new Date(currentDate);
    const currentWeekEndDate = new Date(currentWeekStartDate);
    if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
      currentWeekStartDate.setDate(
        currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
      );
      currentWeekStartDate.setHours(0, 0, 0, 0);
      const currentWeekEndDate = new Date(currentWeekStartDate);
      const getDateNew = moment(currentWeekEndDate);
      const sixDaysAfter = getDateNew.add(6, "days");
      currentWeekEndDate.setDate(sixDaysAfter.format("YYYY-MM-DD"));
      currentWeekEndDate.setHours(23, 59, 59, 999);
    } else {
      currentWeekStartDate.setDate(
        currentDate.getDate() - currentDate.getDay()
      );
      currentWeekStartDate.setHours(0, 0, 0, 0);
      const currentWeekEndDate = new Date(currentWeekStartDate);
      const getDateNew = moment(currentWeekEndDate);
      const sixDaysAfter = getDateNew.add(6, "days");
      currentWeekEndDate.setDate(sixDaysAfter.format("YYYY-MM-DD"));
      currentWeekEndDate.setHours(23, 59, 59, 999);
    }
    const datesInCurrentWeek = dates.filter((date) => {
      return date >= currentWeekStartDate && date <= currentWeekEndDate;
    });

    const highestDate = new Date(Math.max(...datesInCurrentWeek));
    const leastDate = new Date(Math.min(...datesInCurrentWeek));

    return { highestDate, leastDate };
  }

  function findExtremesOfLastWeek(dates) {
    const currentDate = new Date();
    const dateObject = moment();
    const formattedDate = dateObject.format("YYYY-MM-DD");
    const lastWeekStartDate = new Date(formattedDate);

    if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
      lastWeekStartDate.setDate(
        currentDate.getDate() - ((currentDate.getDay() + 6) % 7) - 7
      );
    } else {
      lastWeekStartDate.setDate(
        currentDate.getDate() - currentDate.getDay() - 7
      );
    }

    lastWeekStartDate.setHours(0, 0, 0, 0);
    const lastWeekEndDate = new Date(lastWeekStartDate);
    lastWeekEndDate.setDate(lastWeekStartDate.getDate() + 6);
    lastWeekEndDate.setHours(23, 59, 59, 999);

    const datesInLastWeek = dates.filter((date) => {
      return date >= lastWeekStartDate && date <= lastWeekEndDate;
    });

    const highestLastWeekDate = new Date(Math.max(...datesInLastWeek));
    const leastLastWeekDate = new Date(Math.min(...datesInLastWeek));
    highestLastWeekDate.setHours(0, 0, 0, 0);
    leastLastWeekDate.setHours(0, 0, 0, 0);
    return { highestLastWeekDate, leastLastWeekDate };
  }

  function findExtremesByWeek(dates) {
    const datesByWeek = {};
    for (const date of dates) {
      const weekStartDatefil = moment(date);
      const weekStartDatef = weekStartDatefil.format("YYYY-MM-DD");
      const weekStartDate = new Date(weekStartDatef);
      if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
        weekStartDate.setDate(date.getDate() - ((date.getDay() + 6) % 7));
        weekStartDate.setHours(0, 0, 0, 0);
      } else {
        weekStartDate.setDate(date.getDate() - date.getDay());
        weekStartDate.setHours(0, 0, 0, 0);
      }
      const weekStartDateString = weekStartDate.toISOString();
      if (!datesByWeek[weekStartDateString]) {
        datesByWeek[weekStartDateString] = [];
      }
      datesByWeek[weekStartDateString].push(date);
    }
    const extremesByWeek = [];
    for (const weekDates of Object.values(datesByWeek)) {
      const highestWeekDate = new Date(Math.max(...weekDates));
      const leastDate = new Date(Math.min(...weekDates));
      extremesByWeek.push({ highestWeekDate, leastDate });
    }
    return extremesByWeek;
  }

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };

  const totalResults = timer.length;
  const pageCount = Math.ceil(timer.length / perPage);
  const offset = perPage * selectedPage;
  const currentPageData = timer.slice(offset, offset + perPage);
  const { highestDate, leastDate } = filterAndFindExtremes(
    currentPageData.map((item) => new Date(item[0]))
  );
  const { highestLastWeekDate, leastLastWeekDate } = findExtremesOfLastWeek(
    currentPageData.map((item) => new Date(item[0]))
  );
  const extremesByWeek = findExtremesByWeek(
    currentPageData.map((item) => new Date(item[0]))
  );

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  function getMomentDate(date, format) {
    const dateObject = moment(date, format);
    return dateObject.format("YYYY-MM-DD");
  }

  const findLeastDate = (inputDate, data) => {
    let leastDate = null;
    data.forEach((item) => {
      const itemDate = new Date(item.highestWeekDate);
      const startDate = new Date(item.leastDate);
      const endDate = new Date(item.highestWeekDate);
      if (inputDate >= startDate && inputDate <= endDate) {
        leastDate = item.leastDate;
      }
    });
    return leastDate;
  };

  const findExtremeDate = (inputDate, data) => {
    let leastDate = null;
    let highestDate = null;

    data.forEach((item) => {
      const itemDate = new Date(item.highestWeekDate);
      const startDate = new Date(item.leastDate);
      const endDate = new Date(item.highestWeekDate);
      if (inputDate >= startDate && inputDate <= endDate) {
        leastDate = item.leastDate;
        highestDate = item.highestWeekDate;
      }
    });
    return highestDate;
  };

  useEffect(() => {
    if (
      !isValidTimeFormat(startTimeManual24) ||
      !isValidTimeFormat(endTimeManual24)
    ) {
      setUManualFormatedTimeError(true);
    }
    if (isEndTimeGreater(startTimeManual24, endTimeManual24)) {
      setUManualFormatedTimeError(false);
      setUManualEndTimeError(false);
    } else {
      setUManualFormatedTimeError(true);
      setUManualEndTimeError(true);
    }
  }, [endTimeManual24, startTimeManual24]);

  useEffect(() => {
    const pusher = new Pusher("77dbc32bce6ebdabdbc8", {
      cluster: "ap1",
      encrypted: true,
    });
    const channel = pusher.subscribe(user && user._id);
    channel.bind("timerStatus", (data) => {
      if (data.message === "off") {
        handleEndClick();
      }
      if (data.message === "on") {
        setCheckTimerStatus(!checkTimerStatus);
        setStopLoading(false);
      }
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getOld = async (req, res) => {
      const activeData = await checkTimer(user._id, token);
      if (activeData) {
        setTaskError(false);
        setProjectValidation("");
        setIsRunning(true);
        setIsOnline(true);
        setStartTime(activeData.startTime);
        localStorage.setItem("startTime", JSON.stringify(activeData.startTime));
        dispatch(setTimeData(activeData.startTime));
        localStorage.setItem("isRunning", JSON.stringify(true));
        dispatch(setIsRunningData(true));
        localStorage.setItem("task", JSON.stringify(activeData.task));
        dispatch(setTaskData(activeData.task));
        localStorage.setItem("project", JSON.stringify(activeData.projectName));
        dispatch(setProjectData(activeData.projectName));
        localStorage.setItem("projectId", JSON.stringify(activeData.project));
        dispatch(setProjectIdData(activeData.project));
        localStorage.setItem("tag", JSON.stringify(activeData.tagName));
        dispatch(setTagData(activeData.tagName));
        localStorage.setItem("tagId", JSON.stringify(activeData.tag));
        dispatch(setTagIdData(activeData.tag));
        localStorage.setItem("client", JSON.stringify(activeData.client));
        dispatch(setClientData(activeData.client));
        localStorage.setItem("clientId", JSON.stringify(activeData.clientId));
        dispatch(setClientIdData(activeData.clientId));
        localStorage.setItem("isBillable", JSON.stringify(activeData.billable));
        dispatch(setIsbillableData(activeData.billable));
        if (activeData.sprint) {
          localStorage.setItem(
            "sprintName",
            JSON.stringify(activeData.sprintName)
          );
          dispatch(setSprintNameData(activeData.sprintName));
          localStorage.setItem("sprintId", JSON.stringify(activeData.sprint));
          dispatch(setSprintIdData(activeData.sprint));
        }
        setRunningTask(activeData._id);
        localStorage.setItem("runningTask", JSON.stringify(activeData._id));
        dispatch(setRunningTaskData(activeData._id));
        dispatch(setTimerData(Date.now()));
        setStopLoading(false);
      } else {
        handleEndClick();
        setTaskError(false);
        setProjectValidation("");
        setIsRunning(false);
        localStorage.removeItem("startTime");
        localStorage.removeItem("isRunning");
        localStorage.removeItem("task");
        localStorage.removeItem("project");
        localStorage.removeItem("projectId");
        localStorage.removeItem("tag");
        localStorage.removeItem("tagId");
        localStorage.removeItem("client");
        localStorage.removeItem("clientId");
        localStorage.removeItem("isBillable");
        localStorage.removeItem("sprintName");
        localStorage.removeItem("sprintId");
        setRunningTask(null);
        localStorage.removeItem("runningTask");
      }
    };
    getOld();
  }, [checkTimerStatus]);

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="time-tracker-outer time-tracker-outer-import-section">
        <div className="import-tracker-top-section">
          <h2>Timesheet Import</h2>
          <div className="cancel-import-tracker">
            <button className="cancelbtn-link" onClick={props.onCancel}>
              <img src={cancelImage} alt="cancel" /> Cancel
            </button>
          </div>
        </div>

        {(isLoading || isGroupedTimerDataLoading) && (
          <Modal
            isOpen={loadingModalIsOpen}
            onRequestClose={() => setLoadingModalIsOpen(false)}
            style={{
              content: {
                height: "10%",
                width: "10%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            <Spinner />
          </Modal>
        )}
        <ToastContainer />
        <div className="tracker-data-import">
          {timer.length > 0 ? (
            currentPageData.map((item, index) => (
              <div className="tracker-data-import-inner">
                {new Date(item[0]).toDateString() === highestDate.toDateString() && (
                  <div>
                    {timer.length > 0 ? (
                      <div className="total-time-tracked">
                        <div className="total-time-date-left">This Week </div>
                        <div className="total-time-date-right">
                          Week Total:
                          <ThisWeekTotalDisplay
                            startDate={leastDate.toDateString()}
                            endDate={highestDate.toDateString()}
                            selectedUser={selectedUser}
                          />
                        </div>
                      </div>
                    ) : isTimerDataLoading === true ? (
                      <></>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                {new Date(item[0]).toDateString() === highestLastWeekDate.toDateString() && (
                  <div>
                    {timer.length > 0 ? (
                      <div className="total-time-tracked">
                        <div className="total-time-date-left">Last Week</div>
                        <div className="total-time-date-right">
                          Week Total:
                          <WeekTotalDisplay
                            startDate={getMomentDate(
                              leastLastWeekDate,
                              "ddd MMM DD YYYY"
                            )}
                            endDate={getMomentDate(
                              highestLastWeekDate,
                              "ddd MMM DD YYYY"
                            )}
                            selectedUser={selectedUser}
                          />
                        </div>
                      </div>
                    ) : isTimerDataLoading === true ? (
                      <></>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                {extremesByWeek.some(
                  (extreme) =>
                    extreme.highestWeekDate.toDateString() ===
                    new Date(item[0]).toDateString()
                ) &&
                  new Date(item[0]).toDateString() !==
                  highestDate.toDateString() &&
                  new Date(item[0]).toDateString() !==
                  highestLastWeekDate.toDateString() && (
                    <div className="total-time-tracked">
                      <div className="total-time-date-left">
                        {`${moment(getMomentDate(findLeastDate(new Date(item[0]), extremesByWeek), "ddd MMM DD YYYY"))
                          .tz(global.config.getTimeZone())
                          .format("MMM D")} - ${moment(
                            getMomentDate(
                              findExtremeDate(
                                new Date(item[0]),
                                extremesByWeek
                              ).toDateString(),
                              "ddd MMM DD YYYY"
                            )
                          )
                            .tz(global.config.getTimeZone())
                            .format("MMM D")}`}
                        {new Date(item[0]).getFullYear() !==
                          new Date().getFullYear() && (
                            <span>
                              <span>,</span> {new Date(item[0]).getFullYear()}
                            </span>
                          )}
                      </div>

                      <div className="total-time-date-right">
                        Week Total:
                        <WeekTotalDisplay
                          selectedUser={selectedUser}
                          startDate={findLeastDate(
                            new Date(item[0]),
                            extremesByWeek
                          ).toDateString()}
                          endDate={moment(
                            getMomentDate(
                              findExtremeDate(
                                new Date(item[0]),
                                extremesByWeek
                              ).toDateString(),
                              "ddd MMM DD YYYY"
                            )
                          )
                            .tz(global.config.getTimeZone())
                            .format("MMM DD YYYY")}
                        />
                      </div>
                    </div>
                  )}
                <TimeCardImportData
                  index={index}
                  item={item}
                  timeFormat={appSettings.timeFormat}
                />
              </div>
            ))
          ) : isTimerDataLoading === true ? (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>No Results</>
          )}
        </div>
        <Modal
          isOpen={taskModalIsOpen}
          onRequestClose={() => setTaskModalIsOpen(false)}
        >
          <h5>Task</h5>
          <input
            type="text"
            placeholder="What are you working on?"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            autocomplete="off"
          />
          <button onClick={() => setTaskModalIsOpen(false)}>Close Modal</button>
        </Modal>
        <div className="pagination-outer">
          <div class="showing-count">
            {isTimerDataLoading ? (
              <></>
            ) : (
              <>
                {timer.length > 0 ? (
                  <div class="filter-inner showing-result">
                    <label>Showing</label>
                    <div
                      ref={showingDropdownRef}
                      className="select-modal-mainM custom-dropdown-user-new-outer"
                    >
                      <div
                        className="selected-option custom-dropdown-user-new"
                        onClick={() =>
                          setShowingDropdownOpen(!showingDropdownOpen)
                        }
                      >
                        {totalResults < 10
                          ? totalResults.toString()
                          : perPage.toString()}
                      </div>
                      {showingDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <ul className="showing-options-list">
                            {totalResults >= 10 && (
                              <li
                                className={
                                  perPage === 10 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(10);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                10
                              </li>
                            )}
                            {totalResults >= 20 && (
                              <li
                                className={
                                  perPage === 20 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(20);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                20
                              </li>
                            )}
                            {totalResults >= 30 && (
                              <li
                                className={
                                  perPage === 30 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(30);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                30
                              </li>
                            )}
                            {totalResults >= 40 && (
                              <li
                                className={
                                  perPage === 40 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(40);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                40
                              </li>
                            )}
                            {totalResults >= 10 && (
                              <li
                                className={
                                  totalResults >= 10 && perPage === totalResults
                                    ? "selected-item"
                                    : ""
                                }
                                onClick={() => {
                                  setPerPage(totalResults);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                ALL
                              </li>
                            )}
                            {/* Add more conditions as needed */}
                            {totalResults < 10 ? (
                              <li
                                className={
                                  totalResults < 10 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(totalResults);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                {totalResults}
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      )}
                    </div>
                    <label>of {totalResults} Results</label>
                  </div>
                ) : isTimerDataLoading === true ? (
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div className="pagination">
            {isTimerDataLoading ? (
              <></>
            ) : (
              <>
                {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={selectedPage > 0 ? "< Back" : null}
                    nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination maininteampag"}
                    activeClassName={"active"}
                    forcePage={selectedPage}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="confirm-import">
          <button className="button-confirm" disabled={(insertionLoading) && "disabled"} onClick={handleInsertAndClear}>
            {insertionLoading ? (
              <ThreeDots
                height="18"
                width="20"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <>
                <img src={tick} alt="tick" /> Confirm time import
              </>
            )}
          </button>
        </div>
      </div>
      {showTimeZoneModal === true && <TimeZoneModal />}
    </motion.div>
  );
};

export default TrackerImportData;
