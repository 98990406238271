import React, { useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Modal from "react-modal";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Close from "../../../../images/icons/close.svg";
import { calculateCategoryExpenses } from "./Services/reportExpense";

const data = [
  { name: "Rent", currentYear: 44, lastYear: 24 },
  { name: "Utilities", currentYear: 55, lastYear: 39 },
  { name: "Groceries", currentYear: 13, lastYear: 30 },
  { name: "Transport", currentYear: 43, lastYear: 50 },
  { name: "Entertainment", currentYear: 46, lastYear: 27 },
  { name: "Healthcare", currentYear: 55, lastYear: 40 },
  { name: "Miscellaneous", currentYear: 32, lastYear: 18 },
];

// const calculateXTicks = (data) => {
//   if (data.length === 0) return [];

//   const maxDataValue = Math.max(...data.map(item => Math.max(item.currentYear, item.lastYear)));
//   // const tickInterval = 10;
//   const tickInterval = Math.ceil(maxDataValue / 10); // Set the interval between ticks
//   const numOfTicks = Math.ceil(maxDataValue / tickInterval);
//   const ticks = [];

//   // for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
//   //   ticks.push(i * tickInterval);
//   // }
//   for (let i = 0; i <= numOfTicks + 4; i += tickInterval) {
//     ticks.push(i);
//   }
//   return ticks;
// };

const ExpenseHistory = ({
  expenseData,
  expenseDataLoading,
  expenseDataError,
}) => {
  const reportingFinancialExpense = useSelector(
    (state) => state.reportingExpense
  );
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const componentRef = useRef(null);

  const currentYear =
    reportingFinancialExpense.expYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  const setReportingCurrency = useSelector(
    (state) => state.reportingExpense.expCurrency
  );
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const currentYearExpenses = useMemo(
    () =>
      calculateCategoryExpenses(
        expenseData,
        reportingFinancialExpense,
        currencies,
        forex,
        currentYear
      ),
    [expenseData, reportingFinancialExpense, currencies, forex, currentYear]
  );
  const lastYearExpenses = useMemo(
    () =>
      calculateCategoryExpenses(
        expenseData,
        reportingFinancialExpense,
        currencies,
        forex,
        previousYear
      ),
    [expenseData, reportingFinancialExpense, currencies, forex, previousYear]
  );

  const processedData = Object.keys(currentYearExpenses).map((category) => ({
    name: category,
    currentYear: parseFloat(currentYearExpenses[category].toFixed(2)) || 0,
    lastYear: parseFloat(lastYearExpenses[category].toFixed(2)) || 0,
  }));

  const handleBarCurrentYearClick = (data) => {
    setSelectedYear(currentYear);
    setSelectedCategory(data.name);
    setShowModal(true);
  };

  const handleBarLastYearClick = (data) => {
    setSelectedYear(previousYear);
    setSelectedCategory(data.name);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const filteredExpenses = expenseData
    .filter(
      (expense) =>
        expense.exp_category &&
        expense.exp_category.exp_category_name === selectedCategory &&
        !expense.is_delete &&
        selectedYear &&
        new Date(expense.exp_date).getFullYear() === selectedYear &&
        reportingFinancialExpense.expCurrency &&
        reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    )
    .map((expense) => ({
      exp_id: expense.exp_id,
      exp_category_name: expense.exp_category.exp_category_name,
      client_name: expense.exp_client
        ? expense.exp_client.client_name || expense.exp_client.legal_entity_name
        : "Non Billable",
      exp_date: expense.exp_date,
      exp_is_billable: expense.exp_is_billable ? "Yes" : "No",
      amount: expense.exp_amount,
      currency: expense.exp_currency,
      created_at: expense.created_at,
    }));

  const renderStatus = (rowData) => {
    const status = rowData.exp_is_billable;
    const statusClass =
      status === "Yes" ? "payment-status manual" : "payment-status overdue";
    return <span className={`payment-status ${statusClass}`}>{status}</span>;
  };

  const renderDueDate = (rowData) => {
    const dueDate = new Date(rowData.exp_date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return dueDate.toLocaleDateString(undefined, options);
  };

  const renderAmount = (rowData) => {
    var totalAmount = rowData?.amount.toLocaleString("en-US", {
      style: "currency",
      currency: rowData.currency || "USD",
    });

    return `${totalAmount}`;
  };

  if (expenseDataLoading)
    return (
      <div className="outter-load-table">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  if (expenseDataError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(
      ...processedData.map((item) => Math.max(item.currentYear, item.lastYear))
    );
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) {
      // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  };
  // const xAxisTicks = calculateXTicks(processedData);
  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50; // Adjust this value to set the height per bar
    const baseHeight = 100; // Add some base height for padding and labels
    return dataLength * rowHeight + baseHeight;
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div
      className="graph-project-per-hour"
      style={{
        width: "100%",
        height: Math.max(542, calculateChartHeight(processedData.length)),
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: 0, // Adjust the left margin to remove white space
            bottom: 5,
          }}
          // onClick={handleBarClick}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={false}
            vertical={true}
          />
          <XAxis
            type="number"
            domain={[0, "dataMax + 10"]}
            tickFormatter={(tick) =>
              `${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currencies,
              })
                .format(0)
                .replace(/[\d.,]/g, "")}${tick}`
            }
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for expense categories
            tickMargin={10}
          />
          <Tooltip
            formatter={(value) =>
              `${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currencies,
              }).format(value)}`
            }
          />
          <Legend />
          <Bar
            dataKey="currentYear"
            name={
              isCurrentYear
                ? `Current Year (${currentYear})`
                : `Selected Year (${currentYear})`
            }
            onClick={handleBarCurrentYearClick}
            fill="#87ABE2"
          >
            <LabelList
              dataKey="currentYear"
              position="right"
              formatter={(value) =>
                `${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: currencies,
                }).format(value)}`
              }
            />
          </Bar>
          <Bar
            dataKey="lastYear"
            name={
              isCurrentYear
                ? `Last Year (${previousYear})`
                : `Previous Year (${previousYear})`
            }
            onClick={handleBarLastYearClick}
            fill="#C7B6F6"
          >
            <LabelList
              dataKey="lastYear"
              position="right"
              formatter={(value) =>
                `${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: currencies,
                }).format(value)}`
              }
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        className="client-modal sprint-modal add-bank-account-modal account-details-modal report-grpah-filter"
        contentLabel="Invite a User"
        style={{
          content: {
            height: "41%",
            width: "70%",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-40%, -40%)",
          },
        }}
      >
        <div className="modal add-bank-account-content account-details-modal-inner report-grpah-filter-inner">
          <div className="modal-close-invite outter-modal-main">
            <div className="modal-invite-head">
              <h2 className=""> Report Details </h2>
            </div>
            <Link className="" onClick={handleCloseModal}>
              <img src={Close} alt="Edit Icon" />{" "}
            </Link>
          </div>

          <motion.div
            ref={componentRef}
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 2.2 }}
          >
            <div className="modal-body-filter">
              <DataTable
                value={filteredExpenses}
                sort
                tableStyle={{
                  minWidth: "50rem",
                  width: "100%",
                  textAlign: "left",
                }}
                paginator={false}
                paginatorClassName="recent-invoices-pagination"
                emptyMessage={
                  expenseDataLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#405BFF"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "No results found"
                  )
                }
              >
                <Column
                  field="exp_id"
                  header="Expense Id"
                  sortable
                  style={{ width: "12%", paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_category_name"
                  header="Category"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="client_name"
                  header="Client"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_date"
                  body={renderDueDate}
                  header="Date"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_is_billable"
                  body={renderStatus}
                  header="Billable"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="amount"
                  body={renderAmount}
                  className="amount-expense-recent"
                  header="Amount"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
              </DataTable>
            </div>
          </motion.div>
        </div>
      </Modal>
    </div>
  );
};

export default ExpenseHistory;
