import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dollar from "../../../images/icons/total-hours.svg";
import { calculateServicesTotalHours } from './Services/reportServices';

// Functional component
function TotalHoursReportingServices({ trackReport }) {
  const [totalHours, setTotalHours] = useState("00:00:00");
  const reportingServices = useSelector((state) => state.reportingServices);

  useEffect(() => {
    const totalTrackedHours = calculateServicesTotalHours(trackReport, reportingServices);
    setTotalHours(totalTrackedHours);
  }, [trackReport, reportingServices]);

  const changeDurationFormat = (sec) => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedDuration;
  }
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Hours</div>
          <div className="overview-icon">
            <img src={Dollar} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {totalHours ? changeDurationFormat(totalHours) : '00:00:00'}
        </div>
      </div>
    </div>
  );
}

export default TotalHoursReportingServices;
