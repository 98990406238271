// External
import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// External

// Internal
import css from "./Invoicesingle.css";
import InvoiceActions from "./InvoiceActions";
import { ThreeDots } from "react-loader-spinner";
import WXCompany from "../elements/wxCompany.jsx";
import InvoiceAddress from "./elements/InvoiceAddress";
import ItemDescription from "./elements/ItemDescription";
import { INVOICE_STATUS } from "../../../constants/invoice.js";
import userTypeConsts from "../../../constants/userTypeConsts";
import { logout, reset } from "../../../features/auth/authSlice";
import wiseImage from "../../../images/singleinvoice/wise-logo.svg";
import tikgImage from "../../../images/singleinvoice/tick-green.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import PaymentStatusWiseSingle from "../../Invoices/elements/paymentStatusWiseSingle";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { getBankFromCompany, getUserInvoice, bankDetail } from "./services/invoiceSingle.js";
// Internal



const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function getFileExtension(filename) {
  const lastIndex = filename.lastIndexOf(".");
  if (lastIndex !== -1) {
    return filename.slice(lastIndex + 1);
  }
  return "";
}

const InvoiceSingle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [invStatus, setInvStatus] = useState("");
  const [invoiceUserId, setInvoiceUserId] = useState(null);
  const [invoiceView, setInvoiceView] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userWiseProfile, setUserWiseProfile] = useState(null);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [manualModalIsOpen, setManualModalIsOpen] = useState(false);
  const [isLoadingManualPayment, setIsLoadingManualPayment] = useState(false);
  const [wiseSelected, setWiseSelected] = useState("");
  const [bankDetails, setBankDetails] = useState(null);
  const [wxCompany, setWxCompany] = useState(null);
  const [wiseEmail, setWiseEmail] = useState(null);
  const [invBank, setInvBank] = useState(null);
  const [invBankLabel, setInvBankLabel] = useState(null);
  const [wxUserCompany, setWxUserCompany] = useState(null);
  const [wxCompanyDropdownOpen, setWxCompanyDropdownOpen] = useState(false);
  const [wxCompanyBankDropdownOpen, setWxCompanyBankDropdownOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const componentRef = useRef();

  useEffect(() => {

    async function fetchInvoice() {
      try {
        const data = await getUserInvoice(id, token);
        setWxUserCompany(data.inv_for?.user_wx_comapny?.wx_company);
        setWiseEmail(data.inv_email);
        setInvBank(data.inv_bank);
        setInvoiceUserId(data.inv_for._id);
        setWxCompany(data.inv_company_name);
        setWiseSelected(data.inv_bank_profile);
        const invoiceDetails = data.inv_description;
        var costs = data.inv_cost;
        var category = data.inv_category;
        const vats = data.inv_tax;
        const prices = data.inv_price;
        const invoiceViewData = invoiceDetails.map((item, index) => {
          return {
            Description: item.trim(),
            Cost: costs[index],
            Category: category[index],
            VAT: vats[index],
            Price: prices[index],
          };
        });

        setInvoiceView([{ ...data, Invoice_Details: invoiceViewData }]);
        setInvoiceData(data);
        // global.config.activityLog(window.location.href, 'Invoice', `Viewed invoice number:${data.inv_number}`);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }


    fetchInvoice();

    dispatch(setHeaderText("Invoices"));
  }, [id, invStatus, dispatch, token, invoiceUserId, navigate]);


  useEffect(() => {

    async function fetchbankDetails() {

      try {
        const data = await bankDetail(invoiceData, token);
        if (wxUserCompany === "Any") {
          // setWiseSelected(data.profileId);
        }
        setBankDetails(data);
        setInvBankLabel(data.accountSummary);
      }
      catch (error) {
        //global.config.slackMessage(error.toString());
        console.log(error);
      }
    }

    if (invoiceData) {
      fetchbankDetails();
    }

  }, [invoiceData, token, wxUserCompany]);

  useEffect(() => {

    if (invoiceUserId) {
      async function getUserWiseProfile() {
        await axios
          .post(
            "/api/wise/user-wise-profile",
            {
              userId: `${invoiceUserId}`,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setUserWiseProfile(response?.data?.id);

            if (response?.data?.id && response.data.id.length === 1) {
              // setWiseSelected(response?.data && response.data.id[0].id);
            }

          });
      }
      getUserWiseProfile();
    }
  }, [invoiceUserId, wxCompany, token]);

  const handleToggleDropdown = () => {
    setWxCompanyDropdownOpen(!wxCompanyDropdownOpen);
  };

  const handleBankToggleDropdown = () => {
    setWxCompanyBankDropdownOpen(!wxCompanyBankDropdownOpen);

  };

  const handleSelectCompany = (company) => {
    setWxCompany(company);
    setInvBank(null);
    setInvBankLabel(null);
    setWxCompanyDropdownOpen(false);
    setWxCompanyBankDropdownOpen(false);
  };

  const invoiceStatusApprove = async () => {

    try {

      setIsLoadingApprove(true);
      await axios
        .get(`/api/invoice/approve/${id}?company=${wxCompany}&invbank=${invBank}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },

        })
        .then(() => {
          dispatch(setNotification(Date.now()));
        });

      // global.config.activityLog(window.location.href, 'Invoice', `Invoice Approved Successfully.`);
      setInvoiceView((prevState) => {
        const updatedInvoiceView = prevState.map((item) => {
          if (item._id === id) {
            return { ...item, inv_status: "1" };
          }
          return item;
        });
        return updatedInvoiceView;
      });
      setIsLoadingApprove(false);
      closeModal();
      setInvStatus("Approved");
      toast.success("Approved successsfully!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response.data.status === "mailfailed") {
        setInvoiceView((prevState) => {
          const updatedInvoiceView = prevState.map((item) => {
            if (item._id === id) {
              return { ...item, inv_status: "1" };
            }
            return item;
          });
          return updatedInvoiceView;
        });
        setIsLoadingApprove(false);
        closeModal();
        setInvStatus("Approved");
        toast.error("Invoice approved successsfully but something went wrong in sending mail!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error.response.data.message);
      } else {
        toast.error("Something went wrong in approval!");
        console.log(error);
      }
    }

  };

  const invoiceStatusReject = async () => {


    try {
      setIsLoadingReject(true);
      await axios
        .get(`/api/invoice/reject/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(setNotification(Date.now()));
        });
      global.config.activityLog(window.location.href, 'Invoice', `Invoice rejected Successfully.`);
      setInvoiceView((prevState) => {
        const updatedInvoiceView = prevState.map((item) => {
          if (item._id === id) {
            return { ...item, inv_status: "2" };
          }
          return item;
        });
        return updatedInvoiceView;
      });
      setInvStatus("Rejected");
      setIsLoadingReject(false);
      closeRejectModal();
      toast.success("Rejected successsfully!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response.data.status === "mailfailed") {
        setInvoiceView((prevState) => {
          const updatedInvoiceView = prevState.map((item) => {
            if (item._id === id) {
              return { ...item, inv_status: "2" };
            }
            return item;
          });
          return updatedInvoiceView;
        });
        setInvStatus("Rejected");
        setIsLoadingReject(false);
        closeRejectModal();
        toast.error("Invoice rejected successsfully but something went wrong in sending mail!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        console.log(error.response.data.message);
      } else {
        toast.error("Something went wrong in rejection!");
        console.log(error);
      }
    }
  };

  const createPdf = async (e) => {
    e.preventDefault();

    await axios
      .post(`/api/invoice/create`, invoiceView, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) //create pdf next=> get pdf
      .then(() =>
        axios
          .get(`/api/invoice/download/created/pdf`, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }) //to fetch the generated pdf
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            saveAs(pdfBlob, "InvoiceDocument.pdf"); //to save we use file saver
            global.config.activityLog(
              window.location.href,
              "Invoice",
              `Invoice Pdf file Created.`
            );
          })
      );
  };

  const downloadInvDoc = async (invFile) => {
    try {
      const response = await axios.get(`/api/invoice/download/${invFile}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${invFile}`);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Handle error
    }
  };

  // const GetTotalAmount = (amount) => {

  //   return amount.amount;
  // };

  const makePayment = async (
    sourceCurrency,
    targetCurrency,
    targetAmount,
    profile,
    recipientId,
    invoiceId,
    company
  ) => {
    setIsLoadingPayment(true);
    const paymentData = {
      sourceCurrency: sourceCurrency,
      targetCurrency: targetCurrency,
      targetAmount: targetAmount,
      profile: profile,
      recipientId: recipientId,
      invoiceId: invoiceId,
    };

    try {
      await axios
        .post("/api/wise/payment", paymentData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.status === "Error") {

          } else {
            global.config.activityLog(window.location.href, 'Invoice', `Invoice ${invoiceId} (automatic) created.`);
            setInvStatus("paymentProcessing");
            setShowPaymentModal(false);
            toast.success("Payment submitted successfully!", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setIsLoadingPayment(false);
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
          global.config.slackMessage(AxiosError.toString());
          toast.error(`Sorry!.${AxiosError.message}`, {
            position: "bottom-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate(-1);
        });
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
    }
  };

  const handleManualPayment = async () => {
    setManualModalIsOpen(true);
  };

  const handleManualModalConfirm = async (invoiceId) => {
    setIsLoadingManualPayment(true);
    const paymentData = {
      invoice_id: invoiceId,
      payment_with: "manual_payment",
      bank_details: "manual_payment",
      transaction_id: "manual_payment",
    };
    try {
      await axios
        .post("/api/user/payment", paymentData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.status === "Error") {

            toast.error("Something went wrong", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            global.config.activityLog(window.location.href, 'Invoice', `Invoice ${invoiceId} paid manually.`);

            setInvStatus("Payment Processed");
            setManualModalIsOpen(false);
            toast.success("Invoice payment status updated successfully", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setIsLoadingManualPayment(false);
        })
        .catch((AxiosError) => {
          toast.error(`Sorry!.${AxiosError.message}`, {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsLoadingManualPayment(false); // Set isLoadingManualPayment back to false
      setManualModalIsOpen(false);
    }
  };
  const handleManualModalCancel = () => {
    setManualModalIsOpen(false);
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalRejectIsOpen, setRejectIsOpen] = useState(false);

  // const getBaseUrl = () => {
  //   const { protocol, hostname, port } = window.location;
  //   return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  // };

  function openModal() {
    setIsOpen(true);
  }
  function openRejectModal() {
    setRejectIsOpen(true);
  }
  function openPaymentModal() {
    setShowPaymentModal(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function closeRejectModal() {
    setRejectIsOpen(false);
  }
  function closePaymentModal() {
    setShowPaymentModal(false);
  }

  // Add logic to fetch and display the details of a single invoice
  const [banks, setBanks] = useState([]);

  useEffect(() => {

    async function fetchBank() {

      // const response = await axios.get(
      //   `/api/wise/user/banks/${wiseEmail && wiseEmail}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }
      // );

      // const allBanks = response.data;

      var base_url = window.location.origin;
      if (base_url === "https://app.talenton.io" || 1 === 1) {

        if (wxCompany === "Xpertos") {

          setWiseSelected(10873693);

          const getAllBanks = await getBankFromCompany(10873693, wiseEmail && wiseEmail, token);
          if (getAllBanks.length > 0) {

            const filteredBank = getAllBanks.filter((bank) => {

              if (bank.profile === 10873693) {
                return bank;
              }
            });
            setAllBanks(filteredBank);
          }

        }
        if (wxCompany === "WX") {

          setWiseSelected(14760922);
          const getAllBanks = await getBankFromCompany(14760922, wiseEmail && wiseEmail, token);
          if (getAllBanks.length > 0) {

            const filteredBank = getAllBanks.filter((bank) => {

              if (bank.profile === 14760922) {
                return bank;
              }
            });

            setAllBanks(filteredBank);
          }

        }
      } else {

        const getAllBanks = await getBankFromCompany(wiseSelected, wiseEmail && wiseEmail, token);
        if (getAllBanks.length > 0) {

          const filteredBank = getAllBanks.filter((bank) => {

            if (bank.profile === wiseSelected) {
              return bank;
            }
          });

          setAllBanks(filteredBank);
        }

      }
    }

    fetchBank();


  }, [wiseEmail]);

  useEffect(() => {

    var base_url = window.location.origin;
    if (base_url === "https://app.talenton.io") {
      if (wxCompany === "Xpertos") {

        if (banks.length > 0) {

          const filteredBank = banks.filter((bank) => {

            if (bank.profile === 10873693) {
              return bank;
            }
          });

          setBanks(filteredBank);

        }

      }
      if (wxCompany === "WX") {

        if (banks.length > 0) {

          const filteredBank = banks.filter((bank) => {

            if (bank.profile === 14760922) {
              return bank;
            }
          });

          setBanks(filteredBank);
        }

      }
    }

    if (base_url === "http://localhost:3000") {
      if (wxCompany === "Xpertos") {

        if (banks.length > 0) {

          const filteredBank = banks.filter((bank) => {

            if (bank.profile === 16384758) {
              return bank;
            }
          });

          setBanks(filteredBank);
        }

      }
      if (wxCompany === "WX") {
        if (banks.length > 0) {
          const filteredBank = banks.filter((bank) => {
            if (bank.profile === 163847581) {
              return bank;
            }
          });
          allBanks(filteredBank);
        }
      }
    }

  }, [banks]);

  const [allBanks, setAllBanks] = useState([]);

  const wiseProfileChangeHandle = async (wiseProfileId) => {
    setInvBank(null);
    setWiseSelected(null);
    setInvBankLabel(null);
    setWxCompanyBankDropdownOpen(false);
    const getAllBanks = await getBankFromCompany(wiseProfileId, wiseEmail && wiseEmail, token);
    setAllBanks(getAllBanks);

  }


  return (
    <div className="single-ls-info">
      <div className="back-btn-ins">
        <Link to="/user/invoices" className="btnf1-si-s">
          Back to All Invoices
        </Link>
      </div>
      <div className="man-padd-for-siginvo">
        <div>
          <InvoiceActions
            invoiceData={invoiceData}
            invoiceView={invoiceView}
            componentRef={componentRef}
          />
        </div>
        <div className="invoice-main-body">
          <div className="invoice-detail-bs1" ref={componentRef}>
            <div className="main-head-bs11">
              <div className="invoce-id-num">
                <div className="invo-id">Invoice ID </div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    invoiceData.inv_number
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
              <div className="invoce-id-type">
                <div className="invo-id">Type</div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    invoiceData.inv_type
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
              <div className="invoce-id-exdate">
                <div className="invo-id">Due Date</div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    moment(invoiceData.inv_due_date).tz(global.config.getTimeZone()).format("DD/MM/YYYY")
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
              <div className="invoce-id-isdate">
                <div className="invo-id">Issue Date </div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    moment(invoiceData.inv_issue_date).tz(global.config.getTimeZone()).format("DD/MM/YYYY")
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="head-con-date">
              {invoiceData ? (
                invoiceData.inv_title
              ) : (
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              )}
            </div>
            {/* invoice from to */}
            <InvoiceAddress
              invoiceData={invoiceData ? invoiceData.inv_for : null}
              // Company={wxCompany && wxCompany}
              Company={invoiceData ? invoiceData.inv_bank_profile : null}
              invCompany={invoiceData ? invoiceData.inv_company_name : null}
            />
            <div className="table-outtter">
              <ItemDescription invoiceData={invoiceView ? invoiceView : null} key={(Math.random() + 1).toString(36).substring(7)} />
              <div className="other-comment">
                {invoiceData && invoiceData.inv_other_comments && (
                  <>
                    <div className="other-head1">Other comments</div>
                    <div className="other-subhead1">
                      {" "}
                      {invoiceData.inv_other_comments}
                    </div>
                  </>
                )}
              </div>
              {invoiceData && invoiceData.inv_file && invoiceData.inv_file.length > 0 && (
                <div className="attachment-single-invi">
                  <div className="attachment-head">Attachments</div>
                  {Array.isArray(invoiceData.inv_file) ? (
                    invoiceData.inv_file.map((file, index) => (
                      <div
                        className="the-src-pdf-file"
                        onClick={() => downloadInvDoc(file)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="bottom-download-outer">
                          <div className="bottom-download-inner">
                            <div key={index} className="bottom-download-item">
                              <div
                                className="attachment-file-sib-pname"

                              >
                                {file.length > 15 ? (
                                  <span className="shortened-name" title={file}>
                                    {file.slice(0, 15)}...
                                  </span>
                                ) : (
                                  file
                                )}
                              </div>
                              <div className="attachment-file-sib-psize">
                                {file && getFileExtension(file).toUpperCase()}{" "}
                                {invoiceData.inv_file_size && invoiceData.inv_file_size[index] && (
                                  <span>{invoiceData.inv_file_size[index]}</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="bottom-download-inner-right" onClick={() => downloadInvDoc(invoiceData.inv_file)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                              <path d="M5.67409 13.8727C5.69551 13.8928 5.71891 13.9108 5.74395 13.9266L5.77888 13.9439L5.82304 13.9665L5.86918 13.9804L5.90971 13.9909C5.97034 14.003 6.03328 14.003 6.09425 13.9909L6.13478 13.9804L6.18091 13.9665L6.22507 13.9439L6.26 13.9266C6.28537 13.9108 6.30877 13.8928 6.33019 13.8727L10.8939 9.57954C11.0458 9.40745 11.0333 9.1549 10.8649 8.99674C10.6965 8.83858 10.4276 8.82682 10.2444 8.9695L6.46134 12.526V1.43331C6.46134 1.19406 6.25473 1 6 1C5.74527 1 5.53865 1.19406 5.53865 1.43331V12.526L1.75563 8.9695C1.57241 8.82682 1.30351 8.83858 1.13512 8.99674C0.966727 9.1549 0.954205 9.40745 1.10612 9.57954L5.67343 13.8727H5.67409Z" fill="#282828" stroke="#282828" strokeWidth="0.5" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="the-src-pdf-file"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="bottom-download-inner">
                        <div className="bottom-download-item">
                          <div
                            className="attachment-file-sib-pname"
                            onClick={() => downloadInvDoc(invoiceData.inv_file)}
                          >
                            {invoiceData.inv_file.length > 15 ? (
                              <span className="shortened-name" title={invoiceData.inv_file}>
                                {invoiceData.inv_file.slice(0, 15)}...
                              </span>
                            ) : (
                              invoiceData.inv_file
                            )}
                          </div>
                          <div className="attachment-file-sib-psize">
                            {invoiceData.inv_file && getFileExtension(invoiceData.inv_file).toUpperCase()}{" "}
                            {invoiceData.inv_file_size && (
                              <span>{invoiceData.inv_file_size}</span>
                            )}
                          </div>
                          <div className="bottom-download-inner-right" onClick={() => downloadInvDoc(invoiceData.inv_file)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                              <path d="M5.67409 13.8727C5.69551 13.8928 5.71891 13.9108 5.74395 13.9266L5.77888 13.9439L5.82304 13.9665L5.86918 13.9804L5.90971 13.9909C5.97034 14.003 6.03328 14.003 6.09425 13.9909L6.13478 13.9804L6.18091 13.9665L6.22507 13.9439L6.26 13.9266C6.28537 13.9108 6.30877 13.8928 6.33019 13.8727L10.8939 9.57954C11.0458 9.40745 11.0333 9.1549 10.8649 8.99674C10.6965 8.83858 10.4276 8.82682 10.2444 8.9695L6.46134 12.526V1.43331C6.46134 1.19406 6.25473 1 6 1C5.74527 1 5.53865 1.19406 5.53865 1.43331V12.526L1.75563 8.9695C1.57241 8.82682 1.30351 8.83858 1.13512 8.99674C0.966727 9.1549 0.954205 9.40745 1.10612 9.57954L5.67343 13.8727H5.67409Z" fill="#282828" stroke="#282828" strokeWidth="0.5" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="manage-detail-outer">
            <div className="manage-detail-bs2">
              <div className="invoice-manage-singlepg-head">Manage invoice</div>
              <div className="single-invo-sattus">
                <div className="invo-main-status-head">Invoice Status</div>
                <div className="invo-sig-st-btns">
                  {user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) ? (
                    invoiceData ? (
                      invoiceData.inv_payment_status ===
                        INVOICE_STATUS.INVOICE_PAYMENT_NOT_PROCESSING ? (
                        <>
                          {invStatus === "Approved" ||
                            invoiceData.inv_status ===
                            INVOICE_STATUS.INVOICE_APPROVE ? (
                            <>
                              <Link className="Approve-singlebtn  approved ">
                                {" "}
                                Approved <img
                                  src={tikgImage}
                                  alt="Edit Icon"
                                />{" "}
                              </Link>
                              <Link
                                className="Reject-singlebtn"
                                onClick={openRejectModal}
                              >
                                {" "}
                                Reject <img
                                  src={crssrImage}
                                  alt="Edit Icon"
                                />{" "}
                              </Link>
                            </>
                          ) : invStatus === "Rejected" ||
                            invoiceData.inv_status ===
                            INVOICE_STATUS.INVOICE_REJECT ? (
                            <>
                              <Link
                                className="Approve-singlebtn"
                                onClick={openModal}
                              >
                                {" "}
                                Approve <img
                                  src={tikgImage}
                                  alt="Edit Icon"
                                />{" "}
                              </Link>
                              <Link className="Reject-singlebtn rejected">
                                {" "}
                                Rejected <img
                                  src={crssrImage}
                                  alt="Edit Icon"
                                />{" "}
                              </Link>
                            </>
                          ) : invoiceData.inv_status ===
                            INVOICE_STATUS.INVOICE_CANCEL ||
                            invStatus === "Canceled" ? (
                            <Link
                              className="Approve-singlebtn"
                              onClick={openModal}
                            >
                              {" "}
                              Canceled <img
                                src={tikgImage}
                                alt="Edit Icon"
                              />{" "}
                            </Link>
                          ) : invoiceData.inv_status ===
                            INVOICE_STATUS.INVOICE_PENDING ? (
                            <>
                              <Link
                                className="Approve-singlebtn"
                                onClick={openModal}
                              >
                                {" "}
                                Approve <img
                                  src={tikgImage}
                                  alt="Edit Icon"
                                />{" "}
                              </Link>
                              <Link
                                className="Reject-singlebtn"
                                onClick={openRejectModal}
                              >
                                {" "}
                                Reject <img
                                  src={crssrImage}
                                  alt="Edit Icon"
                                />{" "}
                              </Link>
                            </>
                          ) : null}
                        </>
                      ) : (

                        <PaymentStatusWiseSingle
                          inv_payment_details={invoiceData._id}
                          invData={invoiceData}
                        />
                      )
                    ) : (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )
                  ) : invoiceData &&
                    invoiceData.inv_payment_status ===
                    INVOICE_STATUS.INVOICE_PAYMENT_NOT_PROCESSING ? (
                    invoiceData &&
                    (invoiceData.inv_status === INVOICE_STATUS.INVOICE_PENDING ? (
                      <Link className="NotReviewed-singlebtn  Notreviewed ">
                        {" "}
                        Not Reviewed{" "}
                      </Link>
                    ) : invoiceData.inv_status ===
                      INVOICE_STATUS.INVOICE_PAYMENT_PROCESSING ? (
                      <Link className="Approve-singlebtn  approved ">
                        {" "}
                        Approved <img src={tikgImage} alt="Edit Icon" />{" "}
                      </Link>
                    ) : invoiceData.inv_status ===
                      INVOICE_STATUS.INVOICE_REJECT ? (
                      <a className="Reject-singlebtn rejected">
                        {" "}
                        Rejected <img src={crssrImage} alt="Edit Icon" />{" "}
                      </a>
                    ) : invoiceData.inv_status ===
                      INVOICE_STATUS.INVOICE_CANCEL ? (
                      <a className="Approve-singlebtn" onClick={openModal}>
                        {" "}
                        Canceled <img src={tikgImage} alt="Edit Icon" />{" "}
                      </a>
                    ) : (
                      ""
                    ))
                  ) : invoiceData &&
                    invoiceData.inv_payment_status ===
                    INVOICE_STATUS.INVOICE_PAYMENT_PROCESSING ? (
                    <Link className="Approve-singlebtn">
                      {" "}
                      Paid <img src={tikgImage} alt="Edit Icon" />{" "}
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="invo-sig-st-btns1" style={{ paddingTop: '20px' }}>
                {user &&
                  (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) &&
                  invoiceData &&
                  invoiceData.inv_payment_status === 0 && (
                    <>
                      {invoiceData.inv_status === 1 && (
                        <>
                          <div className="payment-btn-sec-head">Payment </div>
                          <div className="invo-sig-st-btns">
                            <Link
                              className="payin-singlebtn"
                              onClick={openPaymentModal}
                            >
                              Pay Invoice
                            </Link>
                            <Link
                              className="prma-singlebtn"
                              onClick={handleManualPayment}
                            >
                              {" "}
                              Process Manually
                            </Link>
                          </div>
                        </>
                      )}
                    </>
                  )}
              </div>
              <div className="payment-comp-detail">
                <div className="pay-app-det2">
                  <span className="head-payapp">Payment App</span>
                  <img src={wiseImage} alt="Edit Icon" />
                </div>
                <div className="company-detail">
                  <span className="head-payapp">Company</span>
                  <span className="companynae-sig">
                    {wxCompany &&
                      (wxCompany === "test" || wxCompany === "") && invoiceData ? setWxCompany(invoiceData && invoiceData.inv_for.user_wx_comapny.wx_company) : wxCompany}
                  </span>
                </div>
              </div>

              {/* modal approve */}

              <div>
                <Modal
                  className="delete-modal"
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Approve Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closeModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Approve Invoice</h2>
                    <p>Are you sure you want to Approve this Invoice</p>


                    {wxUserCompany === "Anyq" && (
                      <>
                        <div className="form-field-payment">
                          <label htmlFor="client" className="form-label">
                            Select Company
                          </label>

                          <div
                            className={`select-modal-mainM custom-dropdown-user-new-outer ${wxCompanyDropdownOpen ? 'open' : ''}`}
                          >
                            <div
                              className="selected-option custom-dropdown-user-new"
                              onClick={handleToggleDropdown}
                            >
                              {wxCompany ? wxCompany : 'Select Company'}
                            </div>
                            {wxCompanyDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <ul className="company-list">
                                  <li className={wxCompany === 'WX' ? 'selected-item' : ''} onClick={() => {

                                    handleSelectCompany('WX');
                                    wiseProfileChangeHandle(14760922);


                                  }

                                  }>WX EUROPE</li>
                                  <li className={wxCompany === 'Xpertos' ? 'selected-item' : ''} onClick={() => {
                                    handleSelectCompany('Xpertos');
                                    wiseProfileChangeHandle(10873693);

                                  }

                                  }>XPERTOS</li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div><br></br>

                        <div className="form-field-payment">
                          <label htmlFor="client" className="form-label">
                            Bank Account
                          </label>
                          <div
                            className={`select-modal-mainM custom-dropdown-user-new-outer ${wxCompanyDropdownOpen ? 'open' : ''}`}
                          >
                            <div
                              className="selected-option custom-dropdown-user-new"
                              onClick={handleBankToggleDropdown}
                            >
                              {invBankLabel ? invBankLabel : 'Select bank'}
                            </div>
                            {wxCompanyBankDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <ul className="company-list">
                                  {allBanks.length > 0 && allBanks.map((bank) =>
                                    (<li className={invBank && parseInt(invBank) === parseInt(bank.id) ? 'selected-item' : ''} key={bank.details.accountNumber} onClick={() => { setInvBank(bank.id); setWiseSelected(bank.profile); setInvBankLabel(`${bank.details.accountNumber} (${bank.currency})`); setWxCompanyBankDropdownOpen(false); }}>{bank.details.accountNumber} ({bank.currency})</li>)
                                  )
                                  }
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <br></br>
                      </>
                    )}

                    {isLoadingApprove ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        <button
                          className="buttons-approved"
                          onClick={() => invoiceStatusApprove(true)}
                          disabled={isLoadingApprove || (invBankLabel === null)}
                        >
                          Approve
                        </button>
                        <button onClick={closeModal}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}

              {/* modal reject */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={modalRejectIsOpen}
                  onRequestClose={closeRejectModal}
                  contentLabel="Reject Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closeRejectModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Reject Invoice</h2>
                    <p>Are you sure you want to Reject this Invoice</p>
                    {isLoadingReject ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons-reject">
                        <button
                          className="buttons-rejected"
                          onClick={() => invoiceStatusReject()}
                          disabled={isLoadingReject}
                        >
                          Reject
                        </button>
                        <button onClick={closeRejectModal}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}

              {/* payment modal */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={manualModalIsOpen}
                  onRequestClose={handleManualModalCancel}
                  contentLabel="Manual Payment"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a
                        className="close-image-clr1"
                        onClick={handleManualModalCancel}
                      >
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Manual Payment</h2>
                    <p>Are you sure you want to Pay this Invoice Manually?</p>
                    {isLoadingManualPayment ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        {invoiceData && (
                          <button
                            className="buttons-pay"
                            onClick={() => {
                              handleManualModalConfirm(invoiceData._id);
                            }}
                            disabled={isLoadingManualPayment}
                          >
                            Pay
                          </button>
                        )}
                        <button onClick={handleManualModalCancel}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={showPaymentModal}
                  onRequestClose={closePaymentModal}
                  contentLabel="Payment"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closePaymentModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Payment</h2>
                    <p>Are you sure you want to Pay this Invoice?</p>
                    {isLoadingPayment ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>
                        {/* {wxUserCompany === "Any"  && ( */}
                        {/* <div className="form-field-payment">
                          <label htmlFor="client" className="form-label">
                            Select Wise Account
                          </label>
                          <select
                            required
                            onChange={async (e) => {

                              wiseProfileChangeHandle(e.target.value);
                              setWiseSelected(e.target.value);

                              // if (getBaseUrl() === "https://app.talenton.io" || 1 === 1) {
                              //   if (e.target.value === 14760922) {
                              //     setWxCompany("WX");
                              //   }

                              //   if (e.target.value === 10873693) {
                              //     setWxCompany("Xpertos");
                              //   }
                              // } else {
                              //   setWxCompany("WX");

                              //   setWiseSelected(e.target.value);
                              //   setInvBank(null);
                              //   setWiseSelected(null);
                              //   setInvBankLabel(null);

                              // }
                            }
                            }
                            className="form-select"
                            value={wiseSelected}
                          >
                            <option value="">Select Wise Account</option>
                            {userWiseProfile && userWiseProfile?.length > 0 &&
                              userWiseProfile.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.fullName}
                                  </option>
                                );
                              })}

                          </select>
                        </div> */}

                        {/* <div className="form-field-payment">
                          <label htmlFor="client" className="form-label">
                            Bank Account
                          </label>
                          <div
                            className={`select-modal-mainM custom-dropdown-user-new-outer ${wxCompanyDropdownOpen ? 'open' : ''}`}
                          >
                            <div
                              className="selected-option custom-dropdown-user-new"
                              onClick={handleBankToggleDropdown}
                            >
                              {invBankLabel ? invBankLabel : 'Select bank'}
                            </div>
                            {wxCompanyBankDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <ul className="company-list">
                                  {allBanks.length > 0 && allBanks.map((bank) =>
                                    (<li className={invBank && parseInt(invBank) === parseInt(bank.id) ? 'selected-item' : ''} key={bank.details.accountNumber} onClick={() => { setInvBank(bank.id); setWiseSelected(bank.profile); setInvBankLabel(`${bank.details.accountNumber} (${bank.currency})`); setWxCompanyBankDropdownOpen(false); }}>{bank.details.accountNumber} ({bank.currency})</li>)
                                  )
                                  }
                                </ul>
                              </div>
                            )}
                          </div>
                        </div> */}

                        {/* )} */}
                        <div className="delete-modal-buttons paynow-invoice-sec">
                          {invoiceData && wiseSelected && invBank ? (
                            <button
                              className="buttons-pay"
                              onClick={() => {
                                makePayment(
                                  // "USD",
                                  invoiceData.inv_currency,
                                  invoiceData.inv_currency,
                                  invoiceData.inv_total_amount,
                                  wiseSelected,
                                  invoiceData.inv_bank,
                                  invoiceData._id,
                                  wxUserCompany
                                );
                              }}
                              disabled={isLoadingPayment}
                            >
                              Pay
                            </button>
                          ) : (
                            <button
                              className="buttons-pay buttons-pay-disabled"
                              disabled
                            >
                              Pay
                            </button>

                          )}
                          <button
                            className="buttons-pay-cancel"
                            onClick={closePaymentModal}
                          >
                            Cancel
                          </button>
                          {/* {wiseSelected && wiseSelected}/  {invoiceData && invoiceData.inv_currency}/ {invoiceData && invoiceData.inv_total_amount}/
                          {wiseSelected && wiseSelected}/ {invoiceData && invoiceData.inv_bank}/zz{invBank}/ {invoiceData && invoiceData._id}/{wxCompany} */}
                        </div>
                      </>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}
            </div>
            <div className="manage-detail-bs2">
              <div className="invoice-manage-singlepg-head">Recipient Details</div>
              <div class="company-detail">
                <div class="head-payapp">Account Holder Name</div>
                <div class="companynae-sig">{bankDetails ? bankDetails.name.fullName : <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />}</div>
              </div>
              <div class="company-detail">
                <div class="head-payapp">Currency</div>
                <div class="companynae-sig">{bankDetails ? bankDetails.currency : <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />}</div>
              </div>
              <div class="company-detail">
                <div class="head-payapp">Account IBAN Number </div>
                <div class="companynae-sig">{bankDetails ? bankDetails.accountSummary : <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default InvoiceSingle;