import { AES } from 'crypto-js';
import Css from "./AdminNavbar.css";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo/to-logo.svg";
import { useSelector, useDispatch } from "react-redux";
import Notification from "../Notification/Notification";
import React, { useState, useEffect, useRef } from "react";
import user_profile from "../../images/user/Default_pfp.png";
import { logout, reset } from "../../features/auth/authSlice";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import { resetAccountDetails } from "../../features/accountDetails/accountDetailsSlice.js";
import Pusher from 'pusher-js';
import userCategory from '../../constants/userCategory.js';
import InactivityHandler from "./InactivityHandler.jsx";


const AdminNavbar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [dashboardText, setDashboardText] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const userSectionRef = useRef(null);
  const headerText = useSelector(
    (state) => state.headerText.headerTextState
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  const userId = useSelector((state) => state.auth.user._id);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsActive(!isActive);
    dispatch(resetAccountDetails());
  };

  const profileSetting = () => {
    dispatch(setHeaderText("Profile Settings"));
    localStorage.setItem('activeTab', '0');
    setIsOpen(false);
  };


  useEffect(() => {

    // Initialize Pusher with your app key and cluster



    const pusher = new Pusher('77dbc32bce6ebdabdbc8', {
      cluster: 'ap1',
      encrypted: true,
    });

    // Subscribe to the channel and event you used in the backend
    const channel = pusher.subscribe(userId);
    channel.bind('userLogout', (data) => {

      localStorage.removeItem("user");
      dispatch(logout());
      dispatch(reset());
      dispatch(invoiceReset());
      dispatch(headerReset());
      dispatch(appSettingsReset());
      document.body.classList.remove('open-menu-mob');
      window.location.reload();

    });

    channel.bind('userRefresh', (data) => {
      window.location.reload();
    });




    const channel2 = pusher.subscribe('allUsers');

    channel2.bind('forceLogout', (data) => {

      localStorage.removeItem("user");
      dispatch(logout());
      dispatch(reset());
      dispatch(invoiceReset());
      dispatch(headerReset());
      dispatch(appSettingsReset());
      document.body.classList.remove('open-menu-mob');
      window.location.reload();

    });

    channel2.bind('forceReload', (data) => {

      window.location.reload();

    });

    // Cleanup on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);


  useEffect(() => {
    const handleBodyClick = (event) => {
      if (userSectionRef.current && !userSectionRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsActive(false);
        document.body.classList.remove("open-menu-mob");
      }
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("open-menu-mob");
    } else {
      document.body.classList.remove("open-menu-mob");
    }
  }, [isActive]);

  useEffect(() => {
    if (headerText) {
      setDashboardText(headerText);
    }
    setProfileImage(user.user.profile_image);
  }, [headerText, user]);

  return (
    <div className='navbar-outer'>
      <div className='navbar-inner'>
        <div className='navbar-lf'>
          {/* <h1 className='logo-main-nav-text-m11'>{global.config.locate(appSettings && appSettings.language, dashboardText)}</h1> */}
          <h1 className='logo-main-nav-text-m11'>{dashboardText}</h1>
          <img className='image-for-mob-only-nav1' src={logo} alt="Logo" />
        </div>
        <div className="navbar-rt">
          {user.user.is_notification_enabled === true && (user && (user.user.userCategory !== userCategory.Vendor) && (user.user.userCategory !== userCategory.Client)) && (
            <div className="nav-noti">
              <Notification />
            </div>
          )}
          <div className={`user-sec ${isActive ? "active" : ""}`} ref={userSectionRef}>
            <div className="user-inner">
              <div className="user-img" onClick={toggleDropdown}>
                {user.isLoading ? (
                  <img src={user_profile} alt="User" />
                ) : (
                  <>
                    {user.user && user.user.profile_image && user.user.profile_image !== "null" ? (
                      <>
                        {profileImage ? (<img
                          // src={`/uploads/orgiProfileImage/${profileImage}`}
                          src={
                            user.user.userCategory === userCategory.Vendor
                              ? `/uploads/vendorCompyLogo/orgiQuality/${profileImage}`
                              : user.user.userCategory === userCategory.Client
                                ? `/uploads/clientCompyLogo/orgiQuality/${profileImage}`
                                : `/uploads/orgiProfileImage/${profileImage}`
                          }
                          alt="User"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/uploads/lowProfileImage/user_default.png';
                          }}
                        />) : "Loading.."}
                      </>
                    ) : (
                      <img src={user_profile} alt="User" />
                    )}
                  </>
                )}
              </div>
              {isOpen && (
                <div className="inner-profile">
                  <Link to="/user/profile/settings" onClick={profileSetting}>
                    {global.config.locate(appSettings && appSettings.language, 'Profile_Settings')}
                  </Link>
                  <Link
                    onClick={(e) => {

                      e.preventDefault();
                      localStorage.removeItem("user");
                      dispatch(logout());
                      dispatch(reset());
                      dispatch(invoiceReset());
                      dispatch(headerReset());
                      dispatch(appSettingsReset());
                      document.body.classList.remove('open-menu-mob');
                      navigate("/");
                    }}
                  >
                    {global.config.locate(appSettings && appSettings.language, 'Logout')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <InactivityHandler />
    </div>

  );
};

export default AdminNavbar;