import "./Graph.css";
import axios from "axios";
import moment from "moment";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from "react";
import { useFetchDashboardChartData } from "./DashboardCurrencyApi";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import { motion } from 'framer-motion';

export default function ClickabeChart(props) {

  const currencySum = props.sum;
  const navigate = useNavigate();
  const dashboradDate = useSelector(
    (state) => state.invoice.dashboardDateRange
  )
  const dashboradCurrency = useSelector(
    (state) => state.invoice.dashboardCurrency
  )
  const { appSettings } = useSelector((state) => state.appSettings);
  const user = useSelector((state) => state.auth.user);

  // const ensureAllMonthsPresent = (data) => {
  //   const allMonths = [
  //     { month: "Jan", count: 0 },
  //     { month: "Feb", count: 0 },
  //     { month: "Mar", count: 0 },
  //     { month: "Apr", count: 0 },
  //     { month: "May", count: 0 },
  //     { month: "Jun", count: 0 },
  //     { month: "Jul", count: 0 },
  //     { month: "Aug", count: 0 },
  //     { month: "Sep", count: 0 },
  //     { month: "Oct", count: 0 },
  //     { month: "Nov", count: 0 },
  //     { month: "Dec", count: 0 },
  //   ];

  //   // Create a map of the existing data for quick lookup
  //   const dataMap = data.reduce((map, item) => {
  //     map[item.month] = item.count;
  //     return map;
  //   }, {});

  //   // Merge the existing data with the allMonths array
  //   return allMonths.map((monthData) => ({
  //     ...monthData,
  //     count: dataMap[monthData.month] || 0,
  //   }));
  // };

  const { data = [{
    month: "Jan",
    count: 0,
  },
  {
    month: "Feb",
    count: 0,
  },
  {
    month: "Mar",
    count: 0,
  },
  {
    month: "Apr",
    count: 0,
  },
  {
    month: "May",
    count: 0,
  }], isLoading, refetch: refetchDashboardChartData } = useFetchDashboardChartData(user.token,
    dashboradCurrency,
    new Date(moment(dashboradDate[0]).tz(global.config.getTimeZone()).startOf('day')).toISOString(),
    new Date(moment(dashboradDate[1]).tz(global.config.getTimeZone()).endOf('day')).toISOString(),
    currencySum
  );

  // const { data: rawData = [], isLoading, refetch: refetchDashboardChartData } = useFetchDashboardChartData(
  //   user.token,
  //   dashboradCurrency,
  //   new Date(moment(dashboradDate[0]).tz(global.config.getTimeZone()).startOf('day')).toISOString(),
  //   new Date(moment(dashboradDate[1]).tz(global.config.getTimeZone()).endOf('day')).toISOString(),
  //   currencySum
  // );

  // // Ensure all months are present in the data
  // const data = ensureAllMonthsPresent(rawData);

  useEffect(() => {
    refetchDashboardChartData();
  }, [dashboradDate, dashboradCurrency, user, currencySum]);

  const graphToInvoice = (monthNumber) => {
    monthNumber = parseInt(monthNumber) + 1;
    navigate(`/user/invoices?month=${monthNumber}`);
  }

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };



  return (
    <div className="graph-main-outer">
      <div className="top-graph">
        <div className="top-inner-graph">
          <div className="graph-title">{global.config.locate(appSettings && appSettings.language, 'Invoices Overview')}</div>
          <div className="icon-graph">
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
              <g filter="url(#filter0_d_3541_12)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M35.8406 34.8157L30.7587 29.2439C32.4556 27.4006 33.4947 24.9674 33.4947 22.2566C33.4932 16.5887 28.9227 12 23.2786 12C17.6346 12 13.0625 16.5887 13.0625 22.2566C13.0625 27.9244 17.6675 32.5131 23.2786 32.5131C25.7381 32.5131 27.9882 31.644 29.754 30.1835L34.8702 35.7914C35.0093 35.931 35.1812 36 35.3547 36C35.5281 36 35.7015 35.931 35.8391 35.7914C36.1157 35.5137 36.1157 35.0964 35.8391 34.8172L35.8406 34.8157ZM23.2786 31.1201C18.3956 31.1201 14.4485 27.1574 14.4485 22.2551C14.4485 17.3527 18.43 13.39 23.2771 13.39C28.1243 13.39 32.1073 17.3527 32.1073 22.2551C32.1073 27.1574 28.1602 31.1201 23.2771 31.1201H23.2786Z" fill="#6A7988" />
              <path d="M27.2031 17.5936L27.2031 25.4686C27.2031 25.7853 26.9345 26.0622 26.6279 26.0622C26.3213 26.0622 26.0526 25.7853 26.0526 25.4686L26.0526 17.5936C26.0526 17.2772 26.321 17 26.6279 17C26.9345 17 27.2031 17.2769 27.2031 17.5936Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.2" />
              <path d="M23.2061 19.5936L23.2061 25.4686C23.2061 25.7853 22.9374 26.0622 22.6308 26.0622C22.3242 26.0622 22.0556 25.7853 22.0556 25.4686L22.0556 19.5936C22.0556 19.2772 22.3239 19 22.6308 19C22.9374 19 23.2061 19.2769 23.2061 19.5936Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.2" />
              <path d="M19.209 21.5936L19.209 25.4686C19.209 25.7853 18.9404 26.0622 18.6337 26.0622C18.3271 26.0622 18.0585 25.7853 18.0585 25.4686L18.0585 21.5936C18.0585 21.2772 18.3269 21 18.6337 21C18.9404 21 19.209 21.2769 19.209 21.5936Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.2" />
              <defs>
                <filter id="filter0_d_3541_12" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3541_12" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3541_12" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <motion.div
        ref={componentRef}
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.2 }}
      >
        <ResponsiveContainer height={212}>
          <BarChart
            data={data}
            
          >
            <XAxis
              dataKey="month"
              interval={0}
              minTickGap={-5}
              // angle={-55}
            />
            <YAxis />
            <Tooltip cursor={false} />
            <CartesianGrid horizontal={true} stroke="#D6D9DB" strokeDasharray="3 3" />
            <Bar
              dataKey="count"
              fill="#96A8BA"
              cursor={"pointer"}
              onClick={(data, i) => { graphToInvoice(i) }}
              barSize={13}
            />
          </BarChart>
        </ResponsiveContainer>
      </motion.div>
    </div>
  );
}