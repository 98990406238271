import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import typeOfContract from '../../../constants/typeOfContract';
import { calculateServicesTotalCost } from './Services/reportServices';
import TotalCost from "../../../images/icons/total-cost.svg";

// Functional component
function ReportServicesTotalCost(props) {
  const [totalCost, setTotalCost] = useState(0);
  const reportingServices = useSelector((state) => state.reportingServices);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const trackReport = props.trackReport;
  const projectCost = props.projectCost;

  
  var currencies = "USD";
  if(props.filteredCurrencies && props.filteredCurrencies.length===1){
     currencies = props.filteredCurrencies[0];
  }else{
    currencies = settings.currency;
  }

  useEffect(() => {
    const totalCostClient = calculateServicesTotalCost(trackReport, projectCost, reportingServices, currencies, forex, typeOfContract);
    setTotalCost(totalCostClient);
  }, [reportingServices, forex, settings.currency, projectCost, trackReport]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Total Cost

          </div>
          <div className="overview-icon">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
              <g filter="url(#filter0_d_1277_8)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M35.31 18.3287V36.4248C35.31 36.6549 35.1913 36.8467 34.954 36.9617C34.8749 37 34.7959 37 34.7168 37C34.5587 37 34.4397 36.9617 34.3211 36.8467L32.9368 35.6577L31.5522 36.8467C31.3149 37.0385 30.9984 37.0385 30.7611 36.8467L29.3766 35.6577L27.992 36.8467C27.7547 37.0385 27.4382 37.0385 27.2009 36.8467L25.8166 35.6577L24.432 36.8467C24.1947 37.0385 23.8783 37.0385 23.641 36.8467L22.2567 35.6577L20.8721 36.8467C20.6348 37.0385 20.3183 37.0385 20.081 36.8467L18.6964 35.6577L17.3119 36.8467C17.1538 37 16.8767 37.0385 16.6789 36.9235C16.4419 36.8467 16.3232 36.6549 16.3232 36.4248V12.5752C16.3232 12.2684 16.6003 12 16.9165 12H28.7832H28.9018C28.9413 12 28.9413 12 28.981 12.0383H29.0205C29.0599 12.0383 29.0599 12.0765 29.0996 12.0765H29.1391C29.1786 12.1148 29.2183 12.1148 29.2183 12.1533L32.185 15.03C33.8857 16.6792 34.6374 17.4081 34.9936 17.7917C35.1517 17.8685 35.2706 18.0218 35.31 18.2136C35.3098 18.2519 35.31 18.2904 35.31 18.3287ZM29.3766 13.9561V17.7534H33.2927C32.1852 16.6794 30.4841 15.03 29.3766 13.9561ZM34.1235 35.159V18.9041H28.7834C28.4669 18.9041 28.1901 18.6354 28.1901 18.3288V13.1506H17.5101V35.159L18.3012 34.4687C18.4198 34.3536 18.5384 34.3154 18.6968 34.3154C18.8549 34.3154 18.9738 34.3536 19.0925 34.4687L20.4771 35.6577L21.8617 34.4687C22.099 34.2769 22.4154 34.2769 22.6527 34.4687L24.0373 35.6577L25.4219 34.4687C25.6592 34.2769 25.9757 34.2769 26.2129 34.4687L27.5975 35.6577L28.9821 34.4687C29.2194 34.2769 29.5359 34.2769 29.7732 34.4687L31.1578 35.6577L32.5423 34.4687C32.7796 34.2769 33.0961 34.2769 33.3334 34.4687L34.1235 35.159ZM31.2816 29.2466H20.4142C20.0977 29.2466 19.821 29.5152 19.821 29.8218C19.821 30.1287 20.098 30.397 20.4142 30.397H31.2816C31.5981 30.397 31.8748 30.1284 31.8748 29.8218C31.8748 29.5152 31.5981 29.2466 31.2816 29.2466Z" fill="#6A7988" />
              <path d="M20.414 26H31.2814C31.5979 26 31.8747 26.2686 31.8747 26.5752C31.8747 26.8818 31.5979 27.1505 31.2814 27.1505H20.414C20.0978 27.1505 19.8208 26.8821 19.8208 26.5752C19.8208 26.2686 20.0976 26 20.414 26Z" fill="#6A7988" />
              <path d="M20.414 22.8495H31.2814C31.5979 22.8495 31.8747 23.1181 31.8747 23.4248C31.8747 23.7314 31.5979 24 31.2814 24H20.414C20.0978 24 19.8208 23.7316 19.8208 23.4248C19.8208 23.1181 20.0976 22.8495 20.414 22.8495Z" fill="#6A7988" />
              <path d="M35.31 18.3287V36.4248C35.31 36.6549 35.1913 36.8467 34.954 36.9617C34.8749 37 34.7959 37 34.7168 37C34.5587 37 34.4397 36.9617 34.3211 36.8467L32.9368 35.6577L31.5522 36.8467C31.3149 37.0385 30.9984 37.0385 30.7611 36.8467L29.3766 35.6577L27.992 36.8467C27.7547 37.0385 27.4382 37.0385 27.2009 36.8467L25.8166 35.6577L24.432 36.8467C24.1947 37.0385 23.8783 37.0385 23.641 36.8467L22.2567 35.6577L20.8721 36.8467C20.6348 37.0385 20.3183 37.0385 20.081 36.8467L18.6964 35.6577L17.3119 36.8467C17.1538 37 16.8767 37.0385 16.6789 36.9235C16.4419 36.8467 16.3232 36.6549 16.3232 36.4248V12.5752C16.3232 12.2684 16.6003 12 16.9165 12H28.7832H28.9018C28.9413 12 28.9413 12 28.981 12.0383H29.0205C29.0599 12.0383 29.0599 12.0765 29.0996 12.0765H29.1391C29.1786 12.1148 29.2183 12.1148 29.2183 12.1533L32.185 15.03C33.8857 16.6792 34.6374 17.4081 34.9936 17.7917C35.1517 17.8685 35.2706 18.0218 35.31 18.2136C35.3098 18.2519 35.31 18.2904 35.31 18.3287ZM29.3766 13.9561V17.7534H33.2927C32.1852 16.6794 30.4841 15.03 29.3766 13.9561ZM34.1235 35.159V18.9041H28.7834C28.4669 18.9041 28.1901 18.6354 28.1901 18.3288V13.1506H17.5101V35.159L18.3012 34.4687C18.4198 34.3536 18.5384 34.3154 18.6968 34.3154C18.8549 34.3154 18.9738 34.3536 19.0925 34.4687L20.4771 35.6577L21.8617 34.4687C22.099 34.2769 22.4154 34.2769 22.6527 34.4687L24.0373 35.6577L25.4219 34.4687C25.6592 34.2769 25.9757 34.2769 26.2129 34.4687L27.5975 35.6577L28.9821 34.4687C29.2194 34.2769 29.5359 34.2769 29.7732 34.4687L31.1578 35.6577L32.5423 34.4687C32.7796 34.2769 33.0961 34.2769 33.3334 34.4687L34.1235 35.159ZM31.2816 29.2466H20.4142C20.0977 29.2466 19.821 29.5152 19.821 29.8218C19.821 30.1287 20.098 30.397 20.4142 30.397H31.2816C31.5981 30.397 31.8748 30.1284 31.8748 29.8218C31.8748 29.5152 31.5981 29.2466 31.2816 29.2466Z" stroke="#6A7988" stroke-width="0.2" />
              <path d="M20.414 26H31.2814C31.5979 26 31.8747 26.2686 31.8747 26.5752C31.8747 26.8818 31.5979 27.1505 31.2814 27.1505H20.414C20.0978 27.1505 19.8208 26.8821 19.8208 26.5752C19.8208 26.2686 20.0976 26 20.414 26Z" stroke="#6A7988" stroke-width="0.2" />
              <path d="M20.414 22.8495H31.2814C31.5979 22.8495 31.8747 23.1181 31.8747 23.4248C31.8747 23.7314 31.5979 24 31.2814 24H20.414C20.0978 24 19.8208 23.7316 19.8208 23.4248C19.8208 23.1181 20.0976 22.8495 20.414 22.8495Z" stroke="#6A7988" stroke-width="0.2" />
              <defs>
                <filter id="filter0_d_1277_8" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1277_8" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1277_8" result="shape" />
                </filter>
              </defs>
            </svg> */}
            <img src={TotalCost} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {totalCost ? `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(totalCost)}` : `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(0)}`}
        </div>
      </div>

    </div>
  );
}

export default ReportServicesTotalCost;
