import React from "react";
import GoogleLoginButton from "./googleLogin/GoogleLoginButton";

function Home() {
    return (
        <div className="react-outerme">
            <div>Home</div>
            <GoogleLoginButton />
        </div>
    );
}

export default Home;
