import axios from "axios";
import "./Notification.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from 'react-loader-spinner';
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import DateConverter from "../../helpers/dateFormatChange";
import { logout, reset } from "../../features/auth/authSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { resetAccountDetails } from "../../features/accountDetails/accountDetailsSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import checkmarkImage from "../../images/Notification-images/check-circle-notification.svg";
import checktickmarkImage from "../../images/Notification-images/check-mark-notification.svg";
import archive from "../../images/Notification-images/archive.svg";
import blueTick from "../../images/Notification-images/blue-tick.svg";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isReadLoading, setIsReadLoading] = useState(false);
  const [isClickedIndex, setIsClickedIndex] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
  const notification = useSelector(
    (state) => state.notification.notificationState
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  const notificationRef = useRef(null);
  let userId = null;
  const user = useSelector((state) => state.auth.user);
  const [readStatus, setReadStatus] = useState(false);

  if (user) {
    if (user && user._id) {
      userId = user._id;
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    dispatch(resetAccountDetails());
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    const handleBodyClass = () => {
      document.body.classList.toggle("open-menu-mob", !isOpen);
    };
    document.body.addEventListener("click", handleBodyClass);
    return () => {
      document.body.removeEventListener("click", handleBodyClass);
    };
  }, [isOpen]);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "/api/notification/unread/count",
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        
        setNotificationData(response.data);
      } catch (error) {
        console.error(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotificationData();
  }, [notification, userId, dispatch]);

  const deleteNotifi = async (event, notificationId, userId, url) => {
    event.stopPropagation();
    setIsClickedIndex(notificationId);
    const user_id = userId;
    const notification_id = notificationId;
    try {

      await axios.post("/api/notification/notifideleteat", {
        user_id, notification_id, url
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        }
      }).then(() => {
        setIsOpen(false);
        dispatch(setNotification(Date.now()));
      })

    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {

      setIsClickedIndex(null);
    }
  };

  const readNotifi = async (event, notificationId, userId, url) => {
    event.stopPropagation();
    setIsClickedIndex(notificationId);
    const user_id = userId;
    const notification_id = notificationId;
    try {
      await axios.post("/api/notification/updatereadat", {
        user_id, notification_id, url
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        }
      }).then(() => {
        setIsOpen(false);
        dispatch(setNotification(Date.now()));
      })

    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {

      setIsClickedIndex(null);
    }
  };

  const readNotification = async (event, notificationId, userId, url) => {
    event.stopPropagation();
    setIsClickedIndex(notificationId);
    const user_id = userId;
    const notification_id = notificationId;
    try {
      setIsReadLoading(true);
      await axios.post("/api/notification/updatereadat", {
        user_id,
        notification_id,
        url,
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        }
      });

      // Assuming your response from the server contains the updated notification

      setNotificationData((prevState) => {
        return {
          ...prevState,
          notifications: prevState.notifications.map((item) => {
            if (item._id === notification_id) {
              // Mark this notification as read
              return { ...item, read: true };
            }
            return item;
          }),
        };
      });

      dispatch(setNotification(Date.now()));

    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsReadLoading(false);
      setIsClickedIndex(null);
    }
  };

  const markAllAsRead = async () => {
    setIsMarkingAllAsRead(true);
    try {
      const notificationsToMarkAsRead = notificationData.notifications
        .filter(notification => !notification.read)
        .map((notification) => {
          return {
            user_id: userId,
            notification_id: notification._id,
            url: notification.url,
          };
        });

      const batchSize = 200;
      const totalNotifications = notificationsToMarkAsRead.length;
      if (totalNotifications > batchSize) {
        for (let i = 0; i < totalNotifications; i += batchSize) {
          const currentBatch = notificationsToMarkAsRead.slice(i, i + batchSize);

          const response = await axios.post("/api/notification/markallasread", {
            notifications: currentBatch,
          }, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });

          if (response.data.status === "success") {
            // Update the local notification data state here or make a fetch request to update the notifications
            dispatch(setNotification(Date.now()));
            setNotificationData((prevState) => ({
              ...prevState,
              notifications: prevState.notifications.map((item) => ({ ...item, read: true })),
            }));
          }
        }

        // if (response.data.status === "success") {
        //   // Update the local notification data state here or make a fetch request to update the notifications
        //   dispatch(setNotification(Date.now()));
        //   setNotificationData((prevState) => {
        //     return {
        //       ...prevState,
        //       notifications: prevState.notifications.map((item) => {

        //         return { ...item, read: true };
        //       }),
        //     };
        //   });
        //   // Handle success toast or message here
        //   toast.success("All notifications marked as read successfully!", {
        //     position: "bottom-right",
        //     autoClose: 1500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });
        // } else if (response.data.status === "exists") {
        //   // Handle the case when some notifications were already marked as read
        //   dispatch(setNotification(Date.now()));
        //   setNotificationData((prevState) => {
        //     return {
        //       ...prevState,
        //       notifications: prevState.notifications.map((item) => {
        //         return { ...item, read: true };
        //       }),
        //     };
        //   });
        //   toast.warning("Some notifications were already marked as read, other notifications marked as read", {
        //     position: "bottom-right",
        //     autoClose: 1500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });
        // }
        toast.success("All notifications marked as read successfully!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const response = await axios.post("/api/notification/markallasread", {
          notifications: notificationsToMarkAsRead,
        }, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          }
        });

        if (response.data.status === "success") {
          // Update the local notification data state here or make a fetch request to update the notifications
          dispatch(setNotification(Date.now()));
          setNotificationData((prevState) => {
            return {
              ...prevState,
              notifications: prevState.notifications.map((item) => {

                return { ...item, read: true };
              }),
            };
          });
          // Handle success toast or message here
          toast.success("All notifications marked as read successfully!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (response.data.status === "exists") {
          // Handle the case when some notifications were already marked as read
          dispatch(setNotification(Date.now()));
          setNotificationData((prevState) => {
            return {
              ...prevState,
              notifications: prevState.notifications.map((item) => {
                return { ...item, read: true };
              }),
            };
          });
          toast.warning("Some notifications were already marked as read, other notifications marked as read", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      // Handle error toast or message here
      toast.error("Error marking all notifications as read", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } finally {
      setIsMarkingAllAsRead(false); // Set loading state to false
    }
  };

  const unReadNotification = async (event, notificationId, userId, url) => {
    event.stopPropagation();
    setIsClickedIndex(notificationId);
    const user_id = userId;
    const notification_id = notificationId;
    try {
      setIsReadLoading(true);
      await axios.post("/api/notification/deleteReadNotification", {
        user_id,
        notification_id,
        url,
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        }
      });

      // Assuming your response from the server contains the updated notification

      setNotificationData((prevState) => {
        return {
          ...prevState,
          notifications: prevState.notifications.map((item) => {
            if (item._id === notification_id) {
              // Mark this notification as read
              return { ...item, read: false };
            }
            return item;
          }),
        };
      });
      dispatch(setNotification(Date.now()));

    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsReadLoading(false);
      setIsClickedIndex(null);
    }
  };

  const filteredData = notificationData?.notifications?.filter((item) => item.read === readStatus) || [];
  const changeReadStatus = ()=>{
    setReadStatus(!readStatus);
  }

  return (
    <div className="notification-container" ref={notificationRef}>
        <div className="notification-icon" onClick={toggleDropdown}>
          <img
            src={
              require("../../images/Notification-images/notification-icon_Mode.svg")
                .default
            }
            alt="Notification Icon"
          />
          {filteredData.length > 0 && (
            <span className="count">
              {filteredData && filteredData.length}
            </span>
          )}
        </div>
        {isOpen && (
          <div
            className={`notification-dropdown ${
              filteredData.length === 0 ? "no-notifications" : ""
            }`}
          >
            <h3 className="main-notification-heading">
              {global.config.locate(
                appSettings && appSettings.language,
                "Notifications"
              )}
            </h3>
            <div
              className={`noti-top-sec ${
                filteredData && filteredData.length === 0 ? "disabled-noti" : ""
              }`}
            >
              <div
                className={readStatus ? "archive-sec active" : "archive-sec"}
                onClick={() => changeReadStatus()}
              >
                <span className="icon-archive">
                  <img className="archive-img" src={archive} alt="Archive" />
                </span>
                Archive
              </div>
             
             {!readStatus && (<button
                  className="noti-read-all"
                  onClick={markAllAsRead}
                  disabled={isMarkingAllAsRead || filteredData.length === 0}
                >
                  {isMarkingAllAsRead ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                      <path d="M1 4.78947L5.13793 9L13 1" stroke="#405BFF" stroke-opacity="1" stroke-width="2"/>
                    </svg>
                      {global.config.locate(
                        appSettings && appSettings.language,
                        "Mark_All_As_Read"
                      )}
                    </>
                  )}
                </button>)}
              
            </div>
            <ul>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <li
                    className={`notification-listout ${
                      item.read ? "" : "unread"
                    }`}
                    key={item._id}
                  >
                    {isLoading && isClickedIndex === item._id ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>
                        <Link
                          to={item.url}
                          onClick={
                            !item.read
                              ? (e) =>
                                  readNotifi(
                                    e,
                                    item._id,
                                    item.user_id,
                                    item.url
                                  )
                              : null
                          }
                        >
                          <div className="notification-content">
                            <div className="notification-text">
                              {item.notification_data[0].message}
                            </div>
                            <div className="notification-date">
                              <DateConverter date={item.created_at} />
                            </div>
                          </div>
                        </Link>
                        {isReadLoading &&
                        isClickedIndex === item._id ? (
                          <div className="outter-load-table">
                            <RotatingLines
                              strokeColor="grey"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="20"
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="not-outer">
                            {item.read ? (
                              <div className="tooltip1">
                                <span className="tooltiptext">
                                  {global.config.locate(
                                    appSettings && appSettings.language,
                                    "Mark_as_Unread"
                                  )}
                                </span>
                                <img
                                  className="checkmark"
                                  src={checktickmarkImage}
                                  alt="Check Mark"
                                  onClick={(e) =>
                                    unReadNotification(
                                      e,
                                      item._id,
                                      item.user_id,
                                      item.url
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div className="tooltip1">
                                <span className="tooltiptext">
                                  {global.config.locate(
                                    appSettings && appSettings.language,
                                    "Mark_as_Read"
                                  )}
                                </span>
                                <img
                                  className="checkmark"
                                  src={checkmarkImage}
                                  alt="Check Mark"
                                  onClick={(e) =>
                                    readNotification(
                                      e,
                                      item._id,
                                      item.user_id,
                                      item.url
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </li>
                ))
              ) : (
                <li className="notification-empty">
                  <h3>All clear!</h3>
                  <p>You have no new notifications.</p>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
  );
};

export default Notification;